import React, { useState } from "react";

interface SidebarProps {
  onSectionChange: (section: string) => void;
}

const SidebarGestaoEquipe: React.FC<SidebarProps> = ({ onSectionChange }) => {
  const sections = ["Profissionais", "Unidades", "Especialidade", "Serviços"];
  const [selectedSection, setSelectedSection] = useState<string>("Profissionais");

  const handleSectionClick = (section: string) => {
    setSelectedSection(section);
    onSectionChange(section);
  };

  return (
    <div style={{ width: "250px", padding: "20px", borderRight: "1px solid #f7f7f7" }}>
      <h2>Gestão de equipe</h2>
      <br />
      <ul style={{ listStyle: "none", padding: 0 }}>
        {sections.map((section) => (
          <li
            key={section}
            style={{
              padding: "10px",
              cursor: "pointer",
              fontWeight: "bold",
              backgroundColor: selectedSection === section ? "#5503A51A" : "transparent",
              color: selectedSection === section ? "#5503A5" : "black",
              borderRadius: "5px",
            }}
            onClick={() => handleSectionClick(section)}
          >
            {section}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SidebarGestaoEquipe;
