import styled from 'styled-components';

export const ContainerSectionCelphone = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 60px;

  @media screen and (max-width: 1100px) {
    display: block;
  }
`;

export const TextSectionCelphone = styled.div`
  /*  margin-left: 200px; */
  width: 40%;

  @media screen and (max-width: 1400px) {
    margin-left: 10%;
  }

  @media screen and (max-width: 1300px) {
    margin-left: 3%;
  }

  @media screen and (max-width: 1100px) {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  margin-top: 80px;

  h1 {
    color: #3d3d3d;
    font-size: 46px;

    margin-bottom: 30px;

    span {
      color: #7ef14a;
    }

    @media screen and (max-width: 420px) {
      font-size: 2rem;
    }
  }

  p {
    text-align: justify;
    margin-right: auto;
    margin-left: auto;
    font-weight: 500;
    line-height: 1.5rem;
    font-size: 16px;
    color: #4f4f4f;

    margin-bottom: 30px;

    span {
      color: #5503a5;
      font-weight: bold;
    }
  }

  button {
    width: 250px;
    height: 60px;
    border-radius: 12px;
    color: white;
    font-size: 1rem;
    font-weight: bolder;
    border: 0px;
    cursor: pointer;

    /* background: linear-gradient(90deg, #9069ff 0%, #5503a5 100%); */
    background-color: #7c26d0;
    transition: background 0.3s ease-in-out;

    img {
      height: 10px;
      margin-left: 10px;
    }

    &:hover {
      background-color: #5503a5;
      /* background: linear-gradient(90deg, #5503a5 0%, #9069ff 100%); */
    }
  }
`;

export const ContainerSocialMedia = styled.div`
  margin-top: 50px;

  @media screen and (max-width: 1100px) {
    display: none;
  }
`;

export const ContainerSocialMediasIcons = styled.div`
  justify-content: space-between;

  img {
    margin-right: 10px;
  }
`;

export const ImageSectionCelphone = styled.div`
  img {
    width: 100%;
    height: 600px;
  }
`;
