import React, { useState, useEffect } from 'react';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { motion } from 'framer-motion';
import {
  ContainerMensagens,
  FormContainer,
  FormInput,
  FormLabel,
  BotaoEnviar,
  UserList,
  UserItem,
  SelectAllButton,
  AddUserButton,
  ModalOverlay,
  ModalContent,
  CloseModalButton,
  ContainerMessagesButtons,
  LoaderIcon,
  FormInputMessage,
  UploadImageContainer,
} from './style';
import axios from 'axios';
import { toast } from 'react-toastify';
import { gerarUUIDv4 } from '../../utils/gerarUUIDv4/gerarUUIDv4';
import { MessageTemplate } from './components/MessageTemplate/MessageTemplate';
import WhatsAppPreview from './components/WhatsAppPreview';
import { resourceUsage } from 'process';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Carregamento from './components/Carregamento';

const API_URL = 'https://agendamento-app-3b7ya.ondigitalocean.app/api/disparo';

// const API_URL = 'http://localhost:5000/api/disparo';

const Mensagens: React.FC = () => {
  const [selectedMessage, setSelectedMessage] = useState<string>('');
  const [customNameTemplate, setCustomNameTemplate] = useState<string>('');
  const [customMessage, setCustomMessage] = useState<string>('');
  const [customTemplate, setCustomTemplate] = useState<string>('MARKETING');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [showUsers, setShowUsers] = useState<boolean>(false);
  const [templates, setTemplates] = useState<any[]>([]);
  const [isCreatingTemplate, setIsCreatingTemplate] = useState<boolean>(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [newUserName, setNewUserName] = useState<string>('');
  const [newUserPhone, setNewUserPhone] = useState<string>('');

  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [loadingTemplates, setLoadingTemplates] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editUserId, setEditUserId] = useState<string | null>(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleAddUser = async () => {
    if (!newUserName || !newUserPhone) {
      toast.warn('Por favor, preencha todos os campos.');
      return;
    }

    const newUser = { name: newUserName, phone: newUserPhone };

    try {
      const response = await axios.post(API_URL, newUser);
      setUsers((prevUsers) => [...prevUsers, response.data]);
      toast.success('Usuário adicionado com sucesso!');
      handleCloseModal();
    } catch (error) {
      console.error('Erro ao adicionar usuário:', error);
      toast.error('Erro ao adicionar o usuário.');
    }
  };

  const handleEditUser = async () => {
    if (!newUserName || !newUserPhone || !editUserId) {
      toast.warn('Por favor, preencha todos os campos.');
      return;
    }

    try {
      const updatedUser = { name: newUserName, phone: newUserPhone };
      await axios.put(`${API_URL}/${editUserId}`, updatedUser);
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === editUserId ? { ...user, ...updatedUser } : user
        )
      );
      toast.success('Usuário atualizado com sucesso!');
      handleCloseModal();
    } catch (error) {
      console.error('Erro ao editar usuário:', error);
      toast.error('Erro ao atualizar o usuário.');
    }
  };

  const handleDeleteUser = async (userId: string) => {
    try {
      await axios.delete(`${API_URL}/${userId}`);
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
      toast.success('Usuário deletado com sucesso!');
    } catch (error) {
      console.error('Erro ao deletar usuário:', error);
      toast.error('Erro ao deletar o usuário.');
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewUserName('');
    setNewUserPhone('');
    setEditUserId(null);
    setIsEditing(false);
  };

  /* useEffect(() => {
    const loadTemplates = async () => {
      try {
        setLoadingTemplates(true);
        const templatesFromMeta = await MessageTemplate();
        setTemplates(templatesFromMeta);
      } catch (error) {
        console.error('Erro ao carregar os templates:', error);
        toast.error('Erro ao carregar os templates da Meta.');
      } finally {
        setLoadingTemplates(false);
      }
    };

    loadTemplates();
  }, []); */

  /*   const handleSelectMessage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMessage(e.target.value);
  }; */

  const handleSelectMessage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMessage(e.target.value);
    if (e.target.value !== '') {
      setLoadingUsers(true);
      setTimeout(() => {
        setLoadingUsers(false);
        setShowUsers(true);
      }, 1000);
    }
  };

  const handleSelectTemplate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCustomTemplate(e.target.value);
  };

  const handleSelectUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    console.log(selectedUsers);
    console.log(Number(value));
    setSelectedUsers((prev) =>
      checked
        ? [...prev, Number(value)]
        : prev.filter((id) => id !== Number(value))
    );
  };

  useEffect(() => {
    console.log(selectedUsers);
  }, [selectedUsers]);

  const handleSelectAllUsers = () => {
    if (selectedUsers.length === users.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users.map((user) => user.id));
    }
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImageFile(file);

      const fileName = `${gerarUUIDv4()}.png`;
      const fileType = file.type;

      try {
        const uploadedImageLink = await uploadToDigitalOcean(
          file,
          fileName,
          fileType
        );
        if (uploadedImageLink) {
          setUploadedImageLink(uploadedImageLink); // Atualiza o link da imagem
          toast.success('Imagem carregada com sucesso!');
        } else {
          console.error('Erro ao fazer o upload da imagem.');
          toast.error('Erro ao fazer o upload da imagem.');
        }
      } catch (error) {
        console.error('Erro ao fazer upload da imagem:', error);
        toast.error('Erro ao fazer upload da imagem.');
      }
    }
  };

  const handleSendCampaign = async () => {
    if (!selectedMessage && !customMessage) {
      toast.warn('Por favor, selecione um template ou crie uma nova mensagem.');
      return;
    }

    if (selectedUsers.length === 0) {
      toast.warn('Selecione pelo menos um usuário.');
      return;
    }

    const audience = users
      .filter((user) => selectedUsers.includes(user.id))
      .map((user) => ({
        recipient: user.telefone,
        nome: user.nome,
      }));

    console.log(audience);

    const payload = {
      id: gerarUUIDv4(),
      to: 'postmaster@activecampaign.msging.net',
      method: 'set',
      uri: '/campaign/full',
      type: 'application/vnd.iris.activecampaign.full-campaign+json',
      resource: {
        campaign: {
          name: `envioTeste_${gerarUUIDv4()}`,
          campaignType: 'Batch',
          flowId: '8e583361-b943-4114-976a-c4a0b46a77e6',
          stateId: 'a72e6c62-09df-4c2a-9085-a6c51ee1ca80',
          masterState: 'simuladorquartzogptsup1@msging.net',
        },
        audiences: [
          {
            recipient: '+5511956581515',
            messageParam: {
              '1': 'https://scontent.whatsapp.net/v/t61.29466-34/416155502_1753853215437708_7749064930551144891_n.jpg?ccb=1-7&_nc_sid=8b1bef&_nc_ohc=Sc2kVsi2tkEQ7kNvgGb0TBY&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&_nc_gid=APGiS9_N9XiUlA0KfKfYOTO&oh=01_Q5AaIMFnTI4qvKwJ8djG5WvXBi1aNFjJ2fbK5RxCn_KJJQti&oe=67225FD7',
            },
          },
        ], // audience,
        message: {
          messageTemplate: selectedMessage,
          messageParams: ['1'],
        },
      },
    };

    /* createCampaign(selectedMessage); */

    let templateId = null;
    let imageLink = null;

    templates.forEach((template) => {
      if (template.name === selectedMessage) {
        templateId = template.id;
        if (template?.components?.length > 1) {
          const headerImage =
            template?.components[0]?.example?.header_handle?.[0];
          if (headerImage) {
            imageLink = headerImage;
          }
        }
      }
    });

    console.log(imageLink);

    let downloadedImageLink = null;

    if (imageLink) {
      const downloadedImage = await downloadImage(imageLink);
      console.log('Download image: ', downloadedImage);
      if (downloadedImage) {
        const { blob, fileType } = downloadedImage;
        const fileName = `${gerarUUIDv4()}.png`;

        const uploadedImageLink = await uploadToDigitalOcean(
          blob,
          fileName,
          fileType
        );

        if (uploadedImageLink) {
          console.log('Link da imagem no DigitalOcean:', uploadedImageLink);
          downloadedImageLink = uploadedImageLink;
        } else {
          console.error(
            'Erro ao fazer o upload da imagem para o DigitalOcean.'
          );
        }
      }

      if (imageFile) {
        const fileName = `${gerarUUIDv4()}.png`;
        const fileType = imageFile.type;
        const uploadedLink = await uploadToDigitalOcean(
          imageFile,
          fileName,
          fileType
        );

        if (uploadedLink) {
          downloadedImageLink = uploadedLink;
          console.log('Link da imagem no DigitalOcean:', downloadedImageLink);
        } else {
          console.error('Erro ao fazer o upload da imagem.');
          toast.error('Erro ao fazer o upload da imagem.');
          return;
        }
      }
    } else {
      console.error('Nenhuma imagem encontrada para o template selecionado.');
    }

    try {
      /* generateAndSendCSV(
        [
          {
            telefone: '5511956581515',
            imagem:
              'https://scontent.fcgh33-1.fna.fbcdn.net/v/t39.30808-6/461619676_407320329089055_5229527991110831891_n.jpg',
          },
        ],
        templateId
      ); */

      /*     
      number: any,
      templateName: any,
      image: any 
    */

      audience.map((aud) => {
        /* sendMessageToClient(
          aud.recipient,
          selectedMessage,
          downloadedImageLink
        );

        moveContact(aud.recipient, aud.nome);

        updateContact(aud.recipient, aud.nome); */

        createLog(
          aud.recipient,
          aud.nome,
          selectedMessage,
          downloadedImageLink
        );

        /* sendMessageButton(aud.recipient, 'receber_mensagens_sim_nao'); */
      });

      /* sendMessageToClient('5511956581515', selectedMessage, imageLink); */

      /* const response = await axios.post(
        'https://haaify.http.msging.net/commands',
        JSON.stringify(payload),
        {
          headers: {
            Authorization:
              'Key cm90ZWFkb3J0ZXN0ZTY3OmtkdU5JSVlLVTJqTHZZdE9uVnYy',
            'Content-Type':
              'application/vnd.iris.activecampaign.full-campaign+json',
          },
        }
      ); */

      toast.success('Campanha enviada com sucesso!');

      /* if (response.status === 200) {
        console.log('Campanha enviada com sucesso:', response.data);
      } else {
        toast.error('Ocorreu um erro ao enviar');
      } */
    } catch (error) {
      console.error('Erro ao enviar a campanha:', error);
      toast.error('Erro ao enviar a campanha');
    }
  };

  const createLog = async (
    telefone: string,
    nome: string,
    template: string,
    imagem: string
  ) => {
    try {
      await axios.post(`${API_URL}/log/`, {
        telefone,
        nome,
        template,
        imagem,
        variableValues,
      });
    } catch (error) {
      console.error('Erro ao criar o log:', error);
    }
  };

  // const handleCreateTemplate = async () => {
  //   if (!customMessage || !customNameTemplate) {
  //     toast.warn(
  //       'Por favor, insira o texto e o nome do template para criar um novo template.'
  //     );
  //     return;
  //   }

  //   let imageUrl = '';
  //   if (imageFile) {
  //     imageUrl = await uploadToDigitalOcean(imageFile);
  //     if (!imageUrl) {
  //       return;
  //     }
  //   }

  //   const template = await createTemplate(
  //     customNameTemplate,
  //     'pt_BR',
  //     customTemplate,
  //     customMessage,
  //     imageUrl
  //   );

  //   if (template) {
  //     toast.success('Template criado com sucesso! Aguarde a aprovação.');
  //     setIsCreatingTemplate(false);
  //   }
  // };

  const sendMessageToClient = async (
    number: any,
    templateName: any,
    image: any
  ) => {
    let body = null;

    console.log(number);

    const filledVariables = Object.keys(variableValues).map((key) => ({
      type: 'text',
      text: variableValues[key],
    }));

    if (image != null) {
      body = {
        id: gerarUUIDv4(),
        to: `${number}@wa.gw.msging.net`,
        type: 'application/json',
        content: {
          type: 'template',
          template: {
            namespace: '069fb392_d0b6_438a_94f4_88111e94adaa',
            name: `${templateName}`,
            language: {
              code: 'pt_BR',
              policy: 'deterministic',
            },
            components: [
              {
                type: 'header',
                parameters: [
                  {
                    type: 'image',
                    image: {
                      link: image,
                    },
                  },
                ],
              },
              {
                type: 'body',
                parameters: filledVariables,
              },
            ],
          },
        },
      };

      console.log(body);
    } else {
      body = {
        id: gerarUUIDv4(),
        to: `${number}@wa.gw.msging.net`,
        type: 'application/json',
        content: {
          type: 'template',
          template: {
            name: `${templateName}`,
            language: {
              code: 'pt_BR',
              policy: 'deterministic',
            },
            components: [
              {
                type: 'body',
                parameters: filledVariables,
              },
            ],
          },
        },
      };
    }

    console.log('Body payload', body);

    const options = {
      method: 'POST',
      headers: {
        Authorization: 'Key cm90ZWFkb3J0ZXN0ZTY3OmtkdU5JSVlLVTJqTHZZdE9uVnYy',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };

    console.log('ENVIANDO MENSAGEM');

    try {
      const response = await fetch(
        `https://haaify.http.msging.net/messages`,
        options
      );

      const result = await response.json();

      console.log(result);

      if (response.ok) {
        console.log('Mensagem enviada com sucesso:', result);
      } else {
        console.error('Erro ao enviar mensagem:', result);
      }
    } catch (error) {
      console.log(error);
      console.error('Erro na requisição:', error);
    }
  };

  const sendMessageButton = async (number: any, templateName: any) => {
    let body = null;

    body = {
      id: gerarUUIDv4(),
      to: `${number}@wa.gw.msging.net`,
      type: 'application/json',
      content: {
        type: 'template',
        template: {
          name: `${templateName}`,
          language: {
            code: 'pt_BR',
            policy: 'deterministic',
          },
          components: [
            {
              type: 'button',
              sub_type: 'quick_reply',
              index: 0,
              parameters: [
                {
                  type: 'payload',
                  payload: 'sim',
                },
              ],
            },
            {
              type: 'button',
              sub_type: 'quick_reply',
              index: 1,
              parameters: [
                {
                  type: 'payload',
                  payload: 'nao',
                },
              ],
            },
          ],
        },
      },
    };

    console.log('Body payload', body);

    const options = {
      method: 'POST',
      headers: {
        Authorization: 'Key cm90ZWFkb3J0ZXN0ZTY3OmtkdU5JSVlLVTJqTHZZdE9uVnYy',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };

    console.log('ENVIANDO MENSAGEM');

    try {
      const response = await fetch(
        `https://haaify.http.msging.net/messages`,
        options
      );

      const result = await response.json();

      console.log(result);

      if (response.ok) {
        console.log('Mensagem enviada com sucesso:', result);
      } else {
        console.error('Erro ao enviar mensagem:', result);
      }
    } catch (error) {
      console.log(error);
      console.error('Erro na requisição:', error);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  /* const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewUserName('');
    setNewUserPhone('');
  };

  const handleAddUser = () => {
    if (newUserName && newUserPhone) {
      const newUser = {
        id: gerarUUIDv4(),
        name: newUserName,
        phone: newUserPhone,
      };
      setUsers((prevUsers) => [...prevUsers, newUser]);
      handleCloseModal();
    } else {
      toast.warn('Por favor, preencha todos os campos.');
    }
  }; */

  /* CREATE CAMPAIGN WITH CSV */

  const createCampaign = async (selectedMessage: any) => {
    const payload = {
      id: gerarUUIDv4(),
      to: 'postmaster@activecampaign.msging.net',
      method: 'set',
      uri: '/campaign/v2',
      type: 'application/vnd.iris.activecampaign.campaign-dynamic+json',
      resource: {
        campaign: {
          name: `envioTeste_${gerarUUIDv4()}`,
          campaignType: 'batch',
          flowId: '8e583361-b943-4114-976a-c4a0b46a77e6',
          stateId: 'a72e6c62-09df-4c2a-9085-a6c51ee1ca80',
          masterState: 'simuladorquartzogptsup1@msging.net',
        },
      },
      message: {
        messageTemplate: selectedMessage,
      },
    };

    try {
      const response = await axios.post(
        'https://haaify.http.msging.net/commands',
        JSON.stringify(payload),
        {
          headers: {
            Authorization:
              'Key cm90ZWFkb3J0ZXN0ZTY3OmtkdU5JSVlLVTJqTHZZdE9uVnYy',
            'Content-Type':
              'application/vnd.iris.activecampaign.full-campaign+json',
          },
        }
      );
      console.log('Retorno ao criar Campaign: ', response.data);
    } catch (error) {
      console.error('Erro ao enviar a campanha:', error);
    }
  };

  const generateAndSendCSV = async (data: any, campaignId: any) => {
    const csvRows = [];
    const headers = ['telefone', 'imagem'];
    csvRows.push(headers.join(','));

    data.forEach((row: any) => {
      const values = [row.telefone, row.imagem];
      csvRows.push(values.join(','));
    });

    const csvContent = csvRows.join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    sendCSVPayload(blob, campaignId);
  };

  const sendCSVPayload = async (csvBlob: any, campaignId: any) => {
    const formData = new FormData();
    formData.append('file', csvBlob, 'audience.csv');

    const payload = {
      id: gerarUUIDv4(),
      to: 'postmaster@activecampaign.msging.net',
      method: 'set',
      uri: `/audiences/v2/envioTeste_7c24fb3b-6144-49b5-a3a0-e0b81e094b6d/file`,
      type: 'application/vnd.iris.activecampaign.audience-file+json',
      resource: formData,
    };

    try {
      const response = await axios.post(
        'https://haaify.http.msging.net/commands',
        JSON.stringify(payload),
        {
          headers: {
            Authorization:
              'Key cm90ZWFkb3J0ZXN0ZTY3OmtkdU5JSVlLVTJqTHZZdE9uVnYy',
            'Content-Type':
              'application/vnd.iris.activecampaign.full-campaign+json',
          },
        }
      );
      console.log('Payload enviado com sucesso:', response.data);
    } catch (error) {
      console.error('Erro ao enviar payload:', error);
    }
  };

  /* FETCH TEMPLATES */

  /*  const [tokenLong, setTokenLong] = useState<string>('');

  const appId = '1149630489680491';
  const appSecret = 'd3fd2c21211b7f08466a45ed2400a106';
  const shortLivedToken =
    'EAAQVlTT12msBO9Y0z9FZAGEb9OVQglGfGeIbyKOVayNiZCxkFPZCpl5w19DADokZBWaP2ynnj2f2koLOpqFTIhY7ILpOkPkAIx5KZApPZBfomKmGJlXakcLOX1jfwsTPgETZCgFeOScwe6lBqeork9o2Gz5IyiL4b90RvgG3aRcwyGIoMeSLA0i5qiCy8uY8l3hOboMkaeEE31vY6nWcGY9QnuyQ7cZD';

  const getLongLivedToken = async () => {
    try {
      const response = await axios.get(
        `https://graph.facebook.com/v16.0/oauth/access_token`,
        {
          params: {
            grant_type: 'fb_exchange_token',
            client_id: appId,
            client_secret: appSecret,
            fb_exchange_token: shortLivedToken,
          },
        }
      );
      return response.data.access_token;
    } catch (error) {
      console.error('Erro ao obter token de longo prazo:', error);
      toast.error('Erro ao obter o token de longo prazo da Meta.');
      return null;
    }
  }; */

  const fetchTemplates = async () => {
    // const whatsappBusinessAccountId = '107254872058265';
    // const baseUrl = `https://graph.facebook.com/v16.0/${whatsappBusinessAccountId}/message_templates`;
    // let allTemplates: any[] = [];
    // let nextPageUrl = baseUrl; // URL inicial

    try {
      // while (nextPageUrl) {
      //  const response = await axios.get(nextPageUrl, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });

      // allTemplates = [...allTemplates, ...response.data.data];

      // nextPageUrl = response.data.paging?.next || null;
      // }
      const payload = {
        id: gerarUUIDv4(),
        to: 'postmaster@wa.gw.msging.net',
        method: 'get',
        uri: '/message-templates',
      };

      const response = await axios.post(
        'https://haaify.http.msging.net/commands',
        JSON.stringify(payload),
        {
          headers: {
            Authorization:
              'Key cm90ZWFkb3J0ZXN0ZTY3OmtkdU5JSVlLVTJqTHZZdE9uVnYy',
            'Content-Type':
              'application/vnd.iris.activecampaign.full-campaign+json',
          },
        }
      );

      console.log(response.data.resource.data);

      setTemplates(response.data.resource.data);
    } catch (error) {}
  };

  useEffect(() => {
    /* const fetchTokenAndTemplates = async () => {
      const token = await getLongLivedToken();
      if (token) {
        setTokenLong(token);
        fetchTemplates(token);
      }
    }; */

    fetchTemplates();
  }, []);

  /*  */

  const [variables, setVariables] = useState([]);
  const [variableValues, setVariableValues] = useState({});
  const [examplesValues, setExamplesValues] = useState([]);
  const [showVariableForm, setShowVariableForm] = useState(false);

  const detectVariables = (templateText) => {
    const variablePattern = /\{\{\s*(\d+)\s*\}\}/g;
    const variables = [];
    let match;
    while ((match = variablePattern.exec(templateText)) !== null) {
      variables.push(match[1]);
    }
    return variables;
  };

  const [uploadedImageLink, setUploadedImageLink] = useState<string | null>(
    null
  );

  const handleSelectMessageVariables = (e) => {
    const selectedTemplate = templates.find(
      (template) => template.name === e.target.value
    );
    setSelectedMessage(e.target.value);

    if (selectedTemplate && selectedTemplate.components.length > 0) {
      const bodyComponent = selectedTemplate.components.find(
        (c) => c.type === 'BODY'
      );
      if (bodyComponent && bodyComponent.text) {
        const bodyComponentExample = selectedTemplate.components.find(
          (c) => c.type === 'BODY'
        );
        const detectedVariables = detectVariables(bodyComponent.text);
        if (detectedVariables.length > 0) {
          setVariables(detectedVariables);
          setExamplesValues(bodyComponentExample.example.body_text[0]);
          setShowVariableForm(false); // Mostra o formulário de variáveis

          setLoadingUsers(true);
          setTimeout(() => {
            setLoadingUsers(false);
            setShowUsers(true);
          }, 1000);
          return;
        }
      }
    }
    setShowVariableForm(false);
    setLoadingUsers(true);
    setTimeout(() => {
      setLoadingUsers(false);
      setShowUsers(true);
    }, 1000);
  };

  const handleVariableChange = (e, variableKey) => {
    setVariableValues({ ...variableValues, [variableKey]: e.target.value });
  };

  const handleSubmitVariables = () => {
    const allVariablesFilled = variables.every(
      (variable) => variableValues[variable]
    );

    if (!allVariablesFilled) {
      toast.warn('Por favor, preencha todas as variáveis.');
      return;
    }

    setShowVariableForm(false); // Fecha o formulário de variáveis
    setShowUsers(true); // Mostra a lista de usuários
  };

  const replaceVariablesInTemplate = (message, variables) => {
    return message.replace(/\{\{\s*(\d+)\s*\}\}/g, (match, number) => {
      return variables[number] || match; // Se houver um valor para a variável, use-o, caso contrário, mantenha o placeholder
    });
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        'https://agendamento-app-3b7ya.ondigitalocean.app/api/disparo/'
      );
      setUsers(response.data);
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
      toast.error('Erro ao carregar os contatos.');
    }
  };

  const handleSaveUser = async () => {
    if (!newUserName || !newUserPhone) {
      toast.warn('Por favor, preencha todos os campos.');
      return;
    }

    const userPayload = { nome: newUserName, telefone: newUserPhone };

    try {
      if (isEditing && editUserId) {
        await axios.put(`${API_URL}/${editUserId}`, userPayload);
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === editUserId ? { ...user, ...userPayload } : user
          )
        );
        toast.success('Usuário atualizado com sucesso!');
      } else {
        const response = await axios.post(API_URL, userPayload);
        setUsers((prevUsers) => [...prevUsers, response.data]);
        toast.success('Usuário adicionado com sucesso!');
      }
      handleCloseModal();
    } catch (error) {
      console.error('Erro ao salvar usuário:', error);
      toast.error('Erro ao salvar o usuário.');
    }
  };

  const handleOpenEditModal = (
    userId: string,
    userName: string,
    userPhone: string
  ) => {
    setNewUserName(userName);
    setNewUserPhone(userPhone);
    setEditUserId(userId);
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const [editingVariable, setEditingVariable] = useState<string | null>(null);
  const renderTemplateWithVariables = (message: string) => {
    return message.split(/(\{\{\s*\d+\s*\}\})/g).map((part, index) => {
      const match = part.match(/\{\{\s*(\d+)\s*\}\}/);
      if (match) {
        const variableId = match[1];
        console.log('edsit variable', editingVariable);
        console.log(variableId);
        console.log(editingVariable == variableId);
        return editingVariable === variableId ? (
          <input
            key={index}
            type="text"
            value={variableValues[variableId] || ''}
            onChange={(e) => handleVariableChange(e, variableId)}
            onBlur={() => setEditingVariable(null)}
            autoFocus
          />
        ) : (
          <span
            key={index}
            style={{ color: '#7ef14a', fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => setEditingVariable(variableId)}
          >
            {` ${variableValues[variableId] !== null ? (variableValues[variableId] == undefined ? `{{ ${variableId} }}` : variableValues[variableId]) : variableId}`}
          </span>
        );
      }
      // Renderiza o texto fora de variáveis
      return <span key={index}>{part}</span>;
    });
  };

  /***
   *
   *
   */

  const moveContact = async (number: string, nome: string) => {
    console.log(`Moving contact for ${nome} `);

    let options = {
      method: 'POST',
      headers: {
        Authorization: 'Key cm90ZWFkb3J0ZXN0ZTY3OmtkdU5JSVlLVTJqTHZZdE9uVnYy',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: gerarUUIDv4(),
        to: 'postmaster@msging.net',
        method: 'set',
        uri: `/contexts/${number}@wa.gw.msging.net/Master-State`,
        type: 'text/plain',
        resource: `zelotestedisparodev@msging.net`,
      }),
    };

    let response = await (
      await fetch('https://haaify.http.msging.net/commands', options)
    ).json();

    options = {
      method: 'POST',
      headers: {
        Authorization: 'Key cm90ZWFkb3J0ZXN0ZTY3OmtkdU5JSVlLVTJqTHZZdE9uVnYy',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: gerarUUIDv4(),
        to: 'postmaster@msging.net',
        method: 'set',
        uri: `/contexts/${number}@wa.gw.msging.net/stateid@03dc3a57-8451-4a7c-abdd-1c09fb32515d`,
        type: 'text/plain',
        resource: '739adf21-5db8-4539-a761-7ab3efa4ecad',
      }),
    };

    response = await (
      await fetch('https://haaify.http.msging.net/commands', options)
    ).json();
  };

  const updateContact = async (number: string, nome: string) => {
    console.log(`Moving contact for ${nome} `);

    let options = {
      method: 'POST',
      headers: {
        Authorization: 'Key cm90ZWFkb3J0ZXN0ZTY3OmtkdU5JSVlLVTJqTHZZdE9uVnYy',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: gerarUUIDv4(),
        method: 'merge',
        uri: '/contacts',
        type: 'application/vnd.lime.contact+json',
        resource: {
          name: nome,
          identity: `${number}@wa.gw.msging.net`,
          phoneNumber: number,
          extras: {
            nomeDaMensagem: selectedMessage, // selected message é o template
            mensagemValidacao: true,
            Fila: 'nome da louja',
          },
          source: 'WhatsApp',
        },
      }),
    };

    await fetch('https://haaify.http.msging.net/commands', options);
  };

  return (
    <ContainerMensagens>
      <FormContainer>
        {!loadingUsers && !selectedMessage && (
          <motion.div
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: -20 }}
            transition={{ duration: 0.5 }}
          >
            <h2>Enviar Mensagem em Massa</h2>
            <FormLabel>Selecione um template:</FormLabel>
            <FormInput
              as="select"
              value={selectedMessage}
              onChange={handleSelectMessageVariables}
            >
              <option value="">Selecione...</option>
              {templates.map((template: any) => (
                <option key={template.id} value={template.name}>
                  {template.name} -{' '}
                  {template.status === 'APPROVED' ? 'Aprovado' : 'Reprovado'}
                </option>
              ))}
            </FormInput>
          </motion.div>
        )}

        {/* {showVariableForm && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h3>Preencha as variáveis</h3>
            {variables.map((variable) => (
              <div key={variable}>
                <FormLabel>{`Valor para {{ ${variable} }} : Exemplo: ${examplesValues[Number(variable - 1)]}`}</FormLabel>
                <FormInput
                  type="text"
                  onChange={(e) => handleVariableChange(e, variable)}
                  value={variableValues[variable] || ''}
                />
              </div>
            ))}
            
            <br />

            <BotaoEnviar onClick={handleSubmitVariables}>Confirmar</BotaoEnviar>
          </motion.div>
        )} */}
        {showUsers && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <a
              onClick={() => window.location.reload()}
              style={{
                color: 'blue',
                textDecorationLine: 'underline',
                marginLeft: '350px',
                cursor: 'pointer',
              }}
            >
              Voltar
            </a>
            <h3>Selecione os usuários:</h3>
            <br />
            <ContainerMessagesButtons>
              {loadingUsers ? <Carregamento /> : null}
              <AddUserButton onClick={() => setIsModalOpen(true)}>
                Adicionar Usuário
              </AddUserButton>
              <SelectAllButton onClick={handleSelectAllUsers}>
                {selectedUsers.length === users.length
                  ? 'Desmarcar Todos'
                  : 'Selecionar Todos'}
              </SelectAllButton>
            </ContainerMessagesButtons>
            <UserList>
              <br />
              {users.map((user) =>
                user.ativo === true ? (
                  <UserItem key={user.id}>
                    <FormInputMessage
                      title="User"
                      id={`user-${user.id}`}
                      type="checkbox"
                      value={user.id}
                      checked={selectedUsers.includes(user.id)}
                      onChange={handleSelectUsers}
                    />
                    <label htmlFor={`user-${user.id}`}>
                      {user.nome} - {user.telefone}
                    </label>
                    <button
                      title=""
                      onClick={() =>
                        handleOpenEditModal(user.id, user.nome, user.telefone)
                      }
                    >
                      <FaEdit />
                    </button>
                    <button title="" onClick={() => handleDeleteUser(user.id)}>
                      <FaTrash />
                    </button>
                  </UserItem>
                ) : null
              )}
            </UserList>
            <BotaoEnviar onClick={handleSendCampaign}>
              Enviar Campanha
            </BotaoEnviar>

            <UploadImageContainer>
              <FormLabel>Subir Imagem (opcional):</FormLabel>
              <FormInput
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
            </UploadImageContainer>

            <WhatsAppPreview
              template={templates.find(
                (template) => template.name === selectedMessage
              )}
              imageUploadLink={uploadedImageLink}
              previewMessage={renderTemplateWithVariables(
                templates.find((template) => template.name === selectedMessage)
                  ?.components?.[1]?.text ||
                  templates.find(
                    (template) => template.name === selectedMessage
                  )?.components?.[0]?.text ||
                  ''
              )}
            />
          </motion.div>
        )}
      </FormContainer>

      {isModalOpen && (
        <ModalOverlay>
          <ModalContent>
            <h2>{isEditing ? 'Editar Usuário' : 'Adicionar Novo Usuário'}</h2>
            <FormLabel>Nome:</FormLabel>
            <FormInput
              type="text"
              value={newUserName}
              onChange={(e) => setNewUserName(e.target.value)}
              placeholder="Digite o nome do Cliente"
            />
            <FormLabel>Telefone:</FormLabel>
            <FormInput
              type="text"
              value={newUserPhone}
              onChange={(e) => setNewUserPhone(e.target.value)}
              placeholder="Digite o telefone: +553199999999"
            />
            <BotaoEnviar onClick={() => handleSaveUser()}>
              {isEditing ? 'Atualizar Usuário' : 'Adicionar Usuário'}
            </BotaoEnviar>
            <CloseModalButton onClick={() => setIsModalOpen(false)}>
              Cancelar
            </CloseModalButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </ContainerMensagens>
  );
};

export default Mensagens;

const downloadImage = async (url) => {};

const uploadToDigitalOcean = async (
  blob: any,
  fileName: any,
  fileType: any
) => {
  const accessKeyId = 'DO00K3XG7VV9YADVNRJV';
  const secretAccessKey = 'mW7i/rG7yTnjFpEeKIxskCqXVQgd08stCAbvBj+HmmU';
  const bucketName = 'image-meta-template-haaify';
  const region = 'nyc3';

  const s3Client = new S3Client({
    endpoint: `https://${region}.digitaloceanspaces.com`,
    region: region,
    credentials: {
      accessKeyId,
      secretAccessKey,
    },
  });

  const file = new File([blob], fileName, { type: fileType });

  const params = {
    Bucket: bucketName,
    Key: file.name,
    Body: file,
    ACL: 'public-read',
    ContentType: file.type,
  };

  try {
    const data = await s3Client.send(new PutObjectCommand(params));
    console.log(
      'Upload bem-sucedido:',
      `https://${bucketName}.${region}.digitaloceanspaces.com/${file.name}`
    );
    return `https://${bucketName}.${region}.digitaloceanspaces.com/${file.name}`;
  } catch (error) {
    console.error('Erro ao fazer upload da imagem para o bucket:', error);
    return null;
  }
};
