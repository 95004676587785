import styled from 'styled-components';

export const ContainerMission = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  margin-top: 100px;

  @media screen and (max-width: 1250px) {
    margin-top: 40px;
  }
`;

export const MissionText = styled.p`
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
`;

export const MissionImageTag = styled.img`
  width: 600px;
  height: 600px;

  @media screen and (max-width: 700px) {
    width: 100%;
    text-align: justify;
    height: 100%;
  }
`;

export const MissionTitle = styled.h1`
  font-size: 40px;
  line-height: 48px;
  font-weight: bold;
  color: #3d3d3d;
`;

export const MissionDescription = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #3d3d3d;
  text-align: justify;

  margin-top: 20px;

  span {
    font-weight: bold;
  }
`;

export const MissionButtonContainer = styled.div`
  margin-top: 40px;

  button {
    width: 200px;
    padding: 15px;
    border-radius: 10px;
    border: none;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    cursor: pointer;
    background-color: #7c26d0;

    transform: ease-in;
    transition-duration: 0.3s;

    &:hover {
      background-color: #5503a5;
    }
  }
`;

export const ContainerMissionText = styled.div`
  display: block;
  width: 30%;

  margin-right: 40px;

  margin-top: 120px;

  @media screen and (max-width: 1250px) {
    width: 100%;
    margin-right: 0px;
    padding: 30px;
    text-align: center;

    margin-top: 0px;
  }
`;

export const ContainerMissionImage = styled.div`
  display: block;
  @media screen and (max-width: 1250px) {
    width: 100%;
    text-align: center;
  }
`;
