import styled from 'styled-components';

export const ContainerFooter = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;

  @media screen and (max-width: 700px) {
    display: inline-block;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
`;

export const Links = styled.div`
  width: 20%;
  padding: 10px;

  margin-left: 100px;

  @media screen and (max-width: 1500px) {
    width: 30%;
  }

  @media screen and (max-width: 1100px) {
    width: 60%;
  }

  @media screen and (max-width: 700px) {
    width: 80%;

    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 405px) {
    width: 95%;
  }

  ul {
    display: flex;
    list-style: none;
    justify-content: space-between;

    @media screen and (max-width: 700px) {
      justify-content: space-around;
    }

    li {
      font-weight: 500;
      a {
        color: #9a9a9a;
        text-decoration: none;

        &:hover {
          color: #444;
        }
      }
    }
  }
`;

export const SocialMedia = styled.div`
  width: 7%;
  padding: 10px;

  margin-right: 100px;

  color: #9a9a9a;

  @media screen and (max-width: 700px) {
    width: 30%;

    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 405px) {
    width: 95%;
  }

  ul {
    display: flex;
    list-style: none;
    justify-content: space-between;

    @media screen and (max-width: 700px) {
      justify-content: space-around;
    }

    li {
      img {
        width: 24px;
        height: 24px;

        &:hover {
          filter: brightness(120%);
        }
      }
    }
  }
`;
