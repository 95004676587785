import styled from 'styled-components';

export const CarouselContainer = styled.div`
  width: 80%;
  margin: auto;
  padding: 40px 0;

  margin-top: 50px;

  .slick-slide img {
    display: block;
    margin: auto;
  }

  .slick-prev,
  .slick-next {
    z-index: 1;
  }
`;

export const LogoContainer = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 150px;

  img {
    max-width: 100%;
    max-height: 100px;
    object-fit: contain;
  }
`;
