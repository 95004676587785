import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CarouselContainer, LogoContainer } from './style';
import partner1 from '../assets/clients/partner_1.svg';
import partner2 from '../assets/clients/partner_2.svg';
import partner3 from '../assets/clients/partner_3.svg';
import partner4 from '../assets/clients/partner_4.svg';
import partner5 from '../assets/clients/partner_5.svg';
import partner6 from '../assets/clients/partner_6.svg';
import partner7 from '../assets/clients/partner_7.svg';
import partner8 from '../assets/clients/partner_8.svg';
import partner9 from '../assets/clients/partner_9.svg';
import partner10 from '../assets/clients/partner_10.svg';
import partner11 from '../assets/clients/partner_11.svg';

const clients = [
  { src: partner1, alt: 'Client 1' },
  { src: partner2, alt: 'Client 2' },
  { src: partner3, alt: 'Client 3' },
  { src: partner4, alt: 'Client 4' },
  { src: partner5, alt: 'Client 5' },
  { src: partner6, alt: 'Client 6' },
  { src: partner7, alt: 'Client 7' },
  { src: partner8, alt: 'Client 8' },
  { src: partner9, alt: 'Client 9' },
  { src: partner10, alt: 'Client 10' },
  { src: partner11, alt: 'Client 11' },
];

const ClientCarousel: React.FC = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <CarouselContainer>
      <Slider {...settings}>
        {clients.map((client, index) => (
          <LogoContainer key={index}>
            <img src={client.src} alt={client.alt} />
          </LogoContainer>
        ))}
      </Slider>
    </CarouselContainer>
  );
};

export default ClientCarousel;
