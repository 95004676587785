import React, { useEffect, useState } from 'react';
import {
  ContainerRobot,
  ContainerRobotImage,
  ContainerRobotText,
} from './style';
import roboImage from './assets/robot.png';
import axios from 'axios';
import {
  FloatingResponse,
  LoadingMessage,
} from '../Dashboard/components/Navbar/style';

const RobotTed: React.FC = () => {
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [userMessage, setUserMessage] = useState('');
  const [aiResponse, setAiResponse] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSendMessage = async () => {
    if (userMessage.trim()) {
      setAiResponse('');
      setLoading(true);

      try {
        const response = await axios.get(
          `https://langchainhaaify-app-4c2dj.ondigitalocean.app/api/generate-answer?question=${userMessage}`
        );
        setAiResponse(response.data.response);
      } catch (error) {
        setAiResponse('Desculpe, TED está descansando.');
      } finally {
        setLoading(false);
      }
    }
  };

  const [nome, setNome] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        setNome(decodedToken.nome);
      } catch (error) {
        console.error('Erro ao decodificar o token:', error);
      }
    }
  }, []);

  return (
    <>
      <ContainerRobot>
        <ContainerRobotText>
          <h1>Olá, {nome}!</h1>
          <h3>O que vamos fazer hoje?</h3>

          <br />

          <input
            type="text"
            value={userMessage}
            onChange={(e) => setUserMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSendMessage();
            }}
            placeholder="Faça a sua pergunta"
          />

          <br />

          <p>
            Ted: {loading && <LoadingMessage />}
            {aiResponse == '' && !loading
              ? 'Me pergunte que eu te conto! Eu sou Ted, a IA generativa da Haaify! 💚💜'
              : aiResponse}
          </p>
        </ContainerRobotText>

        <ContainerRobotImage>
          <img src={roboImage} alt="logo robo" />
        </ContainerRobotImage>
      </ContainerRobot>
    </>
  );
};

export default RobotTed;
