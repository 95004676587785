import styled from 'styled-components';

export const ContainerMensagens = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const FormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  text-align: center;

  h2 {
    margin-bottom: 20px;
  }

  p {
    color: #9a9a9a;
    margin-bottom: 20px;
  }
`;

export const FormLabel = styled.label`
  display: block;
  margin-bottom: 10px;
  color: #9a9a9a;
`;

export const FormInput = styled.input`
  border-radius: 8px;
  color: black;
  width: 100%;
  height: 50px;
  border: 1px solid #d9d9d9;
  font-size: 14px;
  font-weight: 500;
  text-indent: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  &:focus {
    outline: none;
    border: 1px solid #5503a5;
  }
`;

export const BotaoEnviar = styled.button`
  background-color: #5503a5;
  color: white;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  border: none;
  width: 100%;
  height: 50px;
  margin-top: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;

  transition: 0.3s ease;

  &:hover {
    background-color: #28024d;
  }

  &:active {
    transition: 0.1s ease;
    background-color: #5503a5;
    transform: translateY(2px);
  }
`;

export const UserList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  overflow-y: auto;
  height: 250px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
`;

export const UserItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  input {
    margin: 0;
  }

  label {
    color: #9a9a9a;
    margin-right: auto;
  }

  button {
    transition-duration: 0.3s;

    background-color: #5503a5;
    color: white;

    padding: 10px;
    border-radius: 10px;
    border: 1px solid #5503a5;

    cursor: pointer;

    &:hover {
      background-color: transparent;
      color: #5503a5;
    }
  }
`;

export const ContainerMessagesButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SelectAllButton = styled.button`
  background-color: #5503a5;
  color: white;
  font-size: 12px;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid #5503a5;
  padding: 15px 20px;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  width: 40%;

  cursor: pointer;
  transition: 0.3s ease;

  margin-left: auto;

  &:hover {
    background-color: white;
    color: #5503a5;
    border: 1px solid #5503a5;
  }

  &:active {
    transition: 0.1s ease;
    background-color: #5503a5;
    transform: translateY(2px);
  }
`;

export const AddUserButton = styled.button`
  background-color: white;

  border: 1px solid #5503a5;
  color: #5503a5;
  font-size: 12px;
  font-weight: 500;
  margin-right: auto;

  width: 40%;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    background-color: #5503a5;
    color: white;
  }

  &:active {
    transition: 0.1s ease;
    background-color: #5503a5;
    transform: translateY(2px);
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;
  max-width: 400px;
  text-align: center;

  h2 {
    margin-bottom: 20px;
  }
`;

export const CloseModalButton = styled.button`
  background-color: #ccc;
  color: black;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  border: none;
  width: 100%;
  height: 50px;
  margin-top: 20px;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    background-color: #aaa;
  }

  &:active {
    transition: 0.1s ease;
    background-color: #ccc;
    transform: translateY(2px);
  }
`;

export const FormInputMessage = styled.input`
  border-radius: 8px;
  color: black;
  width: 30px;
  height: 30px;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  border-radius: 10px;
  border: 1px solid #d9d9d9;
  font-size: 14px;
  font-weight: 500;
  text-indent: 10px;

  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid black;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;

  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  &:focus {
    outline: none;
    border: 1px solid #5503a5;
  }

  &:checked {
    background-color: #5503a5;
  }

  &:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: '';
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
  }
`;

export const LoaderIcon = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid #5503a5;
  border-top: 5px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const TextAreaMessage = styled.textarea`
  border-radius: 8px;
  color: black;
  width: 100%;
  height: 100px;
  border: 1px solid #d9d9d9;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  resize: none;

  &:focus {
    outline: none;
    border: 1px solid #5503a5;
  }
`;

export const UploadImageContainer = styled.div`
  position: absolute;
  right: 10%;
  bottom: 12%;
`;
