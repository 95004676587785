import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import Home from './pages/Home/Home';
import Dashboard from './pages/Dashboard/Dashboard';
import Navbar from './pages/Dashboard/components/Navbar/Navbar';
import Forgot from './pages/Forgot/Forgot';
import Mensagens from './pages/Mensagens/Mensagens';
import { DashFlex } from './pages/Dashboard/components/Navbar/style';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ProtectedRoute from './middleware/ProtectedRoute';
import Barbearia from './pages/Barbearia';
import RobotTed from './pages/RobotTed';
import Sair from './utils/sair';
import AccountDetails from './pages/AccountDetails';
import Clientes from './pages/Clientes';
import GestaoEquipe from './pages/GestaoEquipe/GestaoEquipe';
import Calendario from './pages/Calendario';

const App: React.FC = () => {
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/d" element={<ProtectedRoute />}>
            <Route
              path="dash"
              element={
                <DashFlex>
                  <Navbar />
                  <Dashboard />
                </DashFlex>
              }
            />
            <Route
              path="enviomsgs"
              element={
                <DashFlex>
                  <Navbar />
                  <Mensagens />
                </DashFlex>
              }
            />
            <Route
              path="home"
              element={
                <DashFlex>
                  <Navbar />
                  <RobotTed />
                </DashFlex>
              }
            />
            <Route
              path="config"
              element={
                <DashFlex>
                  <Navbar />
                  <AccountDetails />
                </DashFlex>
              }
            />
            <Route
              path="clientes"
              element={
                <DashFlex>
                  <Navbar />
                  <Clientes />
                </DashFlex>
              }
            />
            <Route
              path="gestao"
              element={
                <DashFlex>
                  <Navbar />
                  <GestaoEquipe />
                </DashFlex>
              }
            />
            <Route
              path="calendario"
              element={
                <DashFlex>
                  <Navbar />
                  <Calendario />
                </DashFlex>
              }
            />
            <Route path="sair" element={<Sair />} />
          </Route>

          <Route path="/barbearia" element={<Barbearia />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
