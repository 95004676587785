import React from 'react';
import { ContainerHome } from './style';
import Navbar from './components/Navbar/Navbar';
import SectionInicial from './components/SectionInicial/SectionInicial';
import Partners from './components/Partners/Partners';
import Oportunity from './components/Oportunity/Oportunity';
import Mission from './components/Mission/Mission';
import Pontuation from './components/Pontuation/Pontuation';
import Footer from './components/Footer/Footer';

const Home: React.FC = () => {
  return (
    <ContainerHome>
      <Navbar />
      <SectionInicial />
      <Partners />
      <Oportunity />
      <Mission />
      <Pontuation />
      <Footer />
    </ContainerHome>
  );
};

export default Home;
