import React, { useState } from 'react';
import {
  Container,
  TabMenu,
  ProfilePhotoSection,
  FormSection,
  ButtonSection,
  StyledInput,
  StyledTextArea,
  Button,
  LabelContainer,
  Section,
  NotificationOption,
} from './style';
import profilePlaceholder from './assets/upload.png';

const AccountDetails = () => {
  const [activeTab, setActiveTab] = useState('account');
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [notifications, setNotifications] = useState({
    muteAll: false,
    personalUpdates: false,
    receiveAll: false,
  });

  const handleCheckboxChange = (option) => {
    setNotifications((prev) => ({
      ...prev,
      [option]: !prev[option],
    }));
  };

  return (
    <Container>
      <TabMenu>
        <span
          onClick={() => setActiveTab('account')}
          className={activeTab === 'account' ? 'active' : ''}
        >
          Detalhes da conta
        </span>
        <span
          onClick={() => setActiveTab('login')}
          className={activeTab === 'login' ? 'active' : ''}
        >
          Login & Segurança
        </span>
        <span
          onClick={() => setActiveTab('notifications')}
          className={activeTab === 'notifications' ? 'active' : ''}
        >
          Notificações
        </span>
      </TabMenu>

      {activeTab === 'notifications' && (
        <Section>
          <h2>Gerencie suas notificações.</h2>
          <FormSection>
            <NotificationOption>
              <input
                title="check"
                type="checkbox"
                checked={notifications.muteAll}
                onChange={() => handleCheckboxChange('muteAll')}
              />
              <div>
                <label>Silenciar todas as notificações</label>
                <p>Não receber notificações.</p>
              </div>
            </NotificationOption>

            <NotificationOption>
              <input
                title="check"
                type="checkbox"
                checked={notifications.personalUpdates}
                onChange={() => handleCheckboxChange('personalUpdates')}
              />
              <div>
                <label>
                  Receber notificações apenas de atualizações pessoais
                </label>
                <p>
                  Receber notificações apenas quando você for mencionado ou
                  atribuído a um elemento.
                </p>
              </div>
            </NotificationOption>

            <NotificationOption>
              <input
                title="check"
                type="checkbox"
                checked={notifications.receiveAll}
                onChange={() => handleCheckboxChange('receiveAll')}
              />
              <div>
                <label>Receber todas as notificações</label>
                <p>
                  Receber notificações de tarefas, respostas, automações e mais.
                </p>
              </div>
            </NotificationOption>
          </FormSection>

          <ButtonSection>
            <Button primary>Atualizar</Button>
            <Button>Cancelar</Button>
          </ButtonSection>
        </Section>
      )}

      {activeTab === 'login' && (
        <Section>
          <h2>Gerencie suas configurações de segurança.</h2>
          <FormSection>
            <LabelContainer>
              <label>Senha atual</label>
              <div style={{ position: 'relative' }}>
                <StyledInput
                  type={showPassword ? 'text' : 'password'}
                  placeholder="••••••••"
                />
                {/* <span
                  onClick={togglePasswordVisibility}
                  className="toggle-visibility"
                >
                  {showPassword ? '🙈' : '👁️'}
                </span> */}
              </div>
            </LabelContainer>

            <br />

            <h2>
              Altere sua senha periodicamente para manter sua conta protegida.
            </h2>

            <br />

            <LabelContainer>
              <label>Senha nova</label>
              <div style={{ position: 'relative' }}>
                <StyledInput
                  type={showPassword ? 'text' : 'password'}
                  placeholder="••••••••"
                />
                {/* <span
                  onClick={togglePasswordVisibility}
                  className="toggle-visibility"
                >
                  {showPassword ? '🙈' : '👁️'}
                </span> */}
              </div>
            </LabelContainer>

            <br />

            <LabelContainer>
              <label>Confirme sua nova senha</label>
              <div style={{ position: 'relative' }}>
                <StyledInput
                  type={showPassword ? 'text' : 'password'}
                  placeholder="••••••••"
                />
                {/* <span
                  onClick={togglePasswordVisibility}
                  className="toggle-visibility"
                >
                  {showPassword ? '🙈' : '👁️'}
                </span> */}
              </div>
            </LabelContainer>
          </FormSection>

          <ButtonSection>
            <Button primary>Atualizar</Button>
            <Button>Cancelar</Button>
          </ButtonSection>
        </Section>
      )}

      {activeTab === 'account' && (
        <>
          <ProfilePhotoSection>
            <label>Sua foto de perfil</label>
            <div className="photo-upload">
              <img src={profilePlaceholder} alt="Placeholder" />
              <p>
                Arraste ou <a href="#">Clique para upload</a>
              </p>
              <p>Max: 5MB | JPG, PNG, PDF</p>
            </div>
          </ProfilePhotoSection>

          <hr />
          <br />

          <FormSection>
            <LabelContainer>
              <label>Nome completo</label>
              <StyledInput placeholder="Pedro Saraiva" />
            </LabelContainer>

            <LabelContainer>
              <label>Email</label>
              <StyledInput placeholder="pedro.saraiva@haaify.com" />
            </LabelContainer>

            <LabelContainer>
              <label>Cargo</label>
              <StyledInput placeholder="Profissional" />
            </LabelContainer>

            <LabelContainer>
              <label>Telefone</label>
              <StyledInput placeholder="+55 31 9 45785698" />
            </LabelContainer>

            <LabelContainer>
              <label>Especialidade</label>
              <StyledInput placeholder="Desenvolvedor" />
            </LabelContainer>

            <LabelContainer>
              <label>Unidade</label>
              <StyledInput placeholder="Matriz" />
            </LabelContainer>

            <LabelContainer>
              <label>Bio</label>
              <StyledTextArea placeholder="Escreva uma breve descrição" />
            </LabelContainer>
          </FormSection>

          <ButtonSection>
            <Button primary>Atualizar</Button>
            <Button>Cancelar</Button>
          </ButtonSection>
        </>
      )}
    </Container>
  );
};

export default AccountDetails;
