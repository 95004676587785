import React, { useState } from 'react';
import SidebarGestaoEquipe from './SidebarGestaoEquipe';
import ContentGestaoEquipe from './ContentGestaoEquipe';

const GestaoEquipe: React.FC = () => {
  const [selectedSection, setSelectedSection] = useState('Profissionais');

  return (
    <div
      style={{
        display: 'flex',
        /* height: '100vh', */
        width: '80%',
        marginTop: '50px',
        marginLeft: '50px',
      }}
    >
      <SidebarGestaoEquipe onSectionChange={setSelectedSection} />
      <ContentGestaoEquipe selectedSection={selectedSection} />
    </div>
  );
};

export default GestaoEquipe;
