import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 13%;
  margin-bottom: 10%;
  text-align: center;
  padding: 20px;
`;

export const Logo = styled.img`
  max-width: 200px;
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  font-size: 52px;
  color: #333;
`;

export const Message = styled.p`
  font-size: 24px;
  color: #666;
  margin: 20px 0;
`;

export const HomeLink = styled.a`
  font-size: 18px;
  color: #5503a5;
  text-decoration: none;
  border: 2px solid #5503a5;
  padding: 10px 20px;
  border-radius: 5px;
  transition:
    background-color 0.3s,
    color 0.3s;

  &:hover {
    background-color: #5503a5;
    color: #fff;
  }
`;
