import React, { useEffect, useState } from 'react';
import {
  Container,
  Header,
  FilterBar,
  SearchInput,
  AddButton,
  Table,
  StatusBadge,
  Pagination,
  ModalOverlay,
  ModalContent,
  CloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContainer,
  Tab,
  Form,
  TextField,
  TextArea,
  UploadArea,
  WarningMessage,
  ProgressBarContainer,
  ProgressBar,
  HeaderImport,
} from './style';

import lapis from './assets/lapis.png';
import profilePlaceholder from './assets/upload.png';
import axiosInstance from '../../../utils/axiosInstance';

const TabelaServicoGestaoEquipe = () => {
  const API_URL = '/servico/';

  const [services, setServices] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  /* const renderStatusBadge = (status: any) => {
    switch (status) {
      case 'Em dia':
        return <StatusBadge color="green">● Em dia</StatusBadge>;
      case 'A fazer':
        return <StatusBadge color="orange">● A fazer</StatusBadge>;
      case 'Risco':
        return <StatusBadge color="red">● Risco</StatusBadge>;
      default:
        return null;
    }
  }; */

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [descricao, setDescricao] = useState('');
  const [tempoDuracao, setTempoDuracao] = useState('');
  const [preco, setPreco] = useState('');
  const [selectedServico, setSelectedServico] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'nome':
        setName(value);
        break;
      case 'descricao':
        setDescricao(value);
        break;
      case 'tempoDuracao':
        setTempoDuracao(value);
        break;
      case 'preco':
        setPreco(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getEspecialidades();
    getServicos();
  }, []);

  const getEspecialidades = async () => {
    try {
      const response = await axiosInstance.get('/especialidade/');
      setEspecialidades(response.data);
    } catch (error) {
      console.error('Erro ao buscar especialides:', error);
    }
  };

  const getServicos = async () => {
    try {
      const response = await axiosInstance.get(API_URL);
      setServices(response.data);
    } catch (error) {
      console.error('Erro ao buscar servicos:', error);
    }
  };

  const handleChangeOption = (event) => {
    setSelectedServico(event.target.value);
    console.log('especialidade selecionada:', event.target.value);
  };

  const [activeTab, setActiveTab] = useState('addClient');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const clienteData = {
      nome: name,
      descricao,
      duracao: tempoDuracao,
      preco,
      especialidade_id: selectedServico,
    };

    try {
      const response = await axiosInstance.post(API_URL, clienteData);
      console.log('Serviço adicionado com sucesso:', response);
      closeModal();
      getServicos();
    } catch (error) {
      console.log(error);

      console.error('Erro ao adicionar servico:', error);
    }
  };

  const openModalEdit = async (id) => {
    console.log(id);
    const servicoEdit = services.filter((e) => e.id === id);

    setId(id);
    setName(servicoEdit[0].nome);
    setDescricao(servicoEdit[0].descricao);
    setPreco(servicoEdit[0].preco);
    setTempoDuracao(servicoEdit[0].duracao);
    setSelectedServico(servicoEdit[0].especialidade_id);

    openModal();
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    const clienteData = {
      nome: name,
      descricao,
      duracao: tempoDuracao,
      preco,
      especialidade_id: selectedServico,
    };

    try {
      const response = await axiosInstance.put(`${API_URL}${id}`, clienteData);
      console.log('Serviço editado com sucesso:', response);
      closeModal();
      getServicos();
    } catch (error) {
      console.log(error);

      console.error('Erro ao adicionar Serviço:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`${API_URL}${id}`);
      console.log('Serviço deletado com sucesso:', id);
      closeModal();
      getServicos();
    } catch (error) {
      console.error('Erro ao deletar Serviço:', error);
    }
  };

  return (
    <Container>
      <Header>
        <div>
          <h3>Serviços</h3>
          <br />
          <p>
            Disponibilize os serviços para agendamento, cadastrando todas as
            suas características.
          </p>
        </div>
      </Header>

      <Header>
        <FilterBar>
          {/* <select>
            <option value="">Tudo</option>
            <option value="Em dia">Em dia</option>
            <option value="A fazer">A fazer</option>
            <option value="Risco">Risco</option>
          </select> */}
          <p>
            <b>Serviços cadastrados:</b>
          </p>
          {/* <SearchInput placeholder="Pesquisar" /> */}
        </FilterBar>

        <AddButton
          onClick={() => {
            setId('');
            setName('');
            setDescricao('');
            setTempoDuracao('');
            setPreco('');
            setSelectedServico('');
            openModal();
          }}
        >
          Adicionar +
        </AddButton>
      </Header>

      {/* <FilterBar>
        <nav>
          <a href="#">
            Tudo <span>27</span>
          </a>
          <a href="#">
            Tarefa <span>4</span>
          </a>
          <a href="#">
            Aguardando <span>4</span>
          </a>
          <a href="#">
            Em dia <span>12</span>
          </a>
          <a href="#">
            Arquivado <span>9</span>
          </a>
        </nav>
      </FilterBar> */}

      <Table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Descrição</th>
            <th>Tempo de Duração</th>
            <th>Editar</th>
          </tr>
        </thead>
        <tbody>
          {services.length === 0 ? (
            <>
              <br />
              <h2>Nenhum serviço encontrado.</h2>
            </>
          ) : (
            services.map((service, index) => (
              <tr key={index}>
                <td>{service.nome}</td>
                <td>{service.descricao}</td>
                <td>{service.duracao}</td>
                <td>
                  <button onClick={() => openModalEdit(service.id)}>
                    <img src={lapis} alt="Editar" />
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <Pagination>
        <span>1-2 de 2</span>
        <div>
          <span>Itens por página: 15</span>
          <button>{'<'}</button>
          <span>1 / 2</span>
          <button>{'>'}</button>
        </div>
      </Pagination>

      {isModalOpen && (
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>
              <TabContainer>
                <Tab
                  active={activeTab === 'addClient'}
                  onClick={() => setActiveTab('addClient')}
                >
                  Adicionar Serviço
                </Tab>
                {/* <Tab
                  active={activeTab === 'importClients'}
                  onClick={() => setActiveTab('importClients')}
                >
                  Importar clientes
                </Tab> */}
              </TabContainer>
              <CloseButton onClick={closeModal}>×</CloseButton>
            </ModalHeader>
            <ModalBody>
              {activeTab === 'addClient' && (
                <Form>
                  <div>
                    <label>Nome do Serviço</label>
                    <TextField
                      value={name}
                      onChange={handleChange}
                      name="nome"
                      type="text"
                      placeholder="Nome do Serviço"
                    />
                    <label>Duração do Serviço</label>
                    <TextField
                      value={tempoDuracao}
                      onChange={handleChange}
                      name="tempoDuracao"
                      type="number"
                      placeholder="Digite a duração"
                    />
                    <label>Preço</label>
                    <TextField
                      value={preco}
                      onChange={handleChange}
                      name="preco"
                      type="number"
                      placeholder="Digite o preço"
                    />
                  </div>
                  <div>
                    <label>Especialidade</label>
                    <select
                      value={selectedServico}
                      onChange={handleChangeOption}
                    >
                      <option value="">Selecione a especialidade</option>
                      {especialidades.map((especialidade) => (
                        <option key={especialidade.id} value={especialidade.id}>
                          {especialidade.nome}
                        </option>
                      ))}
                    </select>

                    <label>Descrição</label>
                    <TextArea
                      value={descricao}
                      onChange={handleChange}
                      name="descricao"
                      placeholder="Adicione informações extras..."
                    />
                  </div>
                </Form>
              )}
              {/* {activeTab === 'importClients' && (
                <>
                  <HeaderImport>
                    <UploadArea>
                      <img src={profilePlaceholder} alt="Profile" />
                      <p>
                        Arraste ou <span>Clique para upload</span>
                      </p>
                      <small>Max. 5MB | CSV , Excel</small>
                    </UploadArea>
                    <div>
                      <WarningMessage>
                        <span>Atenção</span> O arquivo precisa estar nos moldes
                        para realizar o upload.
                        <button onClick={closeModal}>×</button>
                      </WarningMessage>
                      <ProgressBarContainer>
                        <p>Nome do documento.xpto</p>
                        <ProgressBar progress={30} />
                        <small>xMB</small>
                      </ProgressBarContainer>
                    </div>
                  </HeaderImport>

                  <Table>
                    <thead>
                      <tr>
                        <th>Cliente</th>
                        <th>Telefone</th>
                        <th>E-mail</th>
                        <th>Editar</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Gustavo</td>
                        <td>31999999999</td>
                        <td>email@email.com.br</td>
                        <td>
                          <button>
                            <img src={lapis} alt="Editar" />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              )} */}
            </ModalBody>
            <ModalFooter>
              <button onClick={closeModal}>Cancelar</button>
              {id !== '' ? (
                <>
                  <button type="submit" onClick={handleDelete}>
                    Deletar Serviço
                  </button>
                  <br />
                  <button type="submit" onClick={handleSubmitEdit}>
                    Editar Serviço
                  </button>
                </>
              ) : (
                <button type="submit" onClick={handleSubmit}>
                  Adicionar Serviço
                </button>
              )}
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
  );
};

export default TabelaServicoGestaoEquipe;
