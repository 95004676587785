import styled from 'styled-components';

export const ContainerLogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const LoginNavBar = styled.div`
  width: 100%;
  text-align: center;

  img {
    margin-top: 30px;
  }
`;

export const FormLogin = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  text-align: center;

  h2 {
    margin-bottom: 20px;
  }

  p {
    color: #9a9a9a;
    margin-bottom: 10px;
  }
`;

export const FormContent = styled.div`
  width: 100%;
  max-width: 375px;
`;

export const ContainerFormCampo = styled.div`
  margin-top: 20px;
  text-align: start;

  label {
    display: block;
    margin-bottom: 10px;
    color: #9a9a9a;
  }
`;

export const FormInput = styled.input`
  border-radius: 8px;
  color: black;
  width: 99%;
  height: 50px;
  border: 1px solid #d9d9d9;
  font-size: 14px;
  font-weight: 500;
  text-indent: 10px;

  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  &:focus {
    outline: none;
    border: 1px solid #5503a5;
  }
`;

export const BotaoLogin = styled.button`
  background-color: #5503a5;
  color: white;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  border: none;
  width: 100%;
  height: 50px;
  margin-top: 20px;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  cursor: pointer;

  transition: 0.3s ease;

  &:hover {
    background-color: #28024d;
  }

  &:active {
    transition: 0.1 s ease;
    background-color: #5503a5;
    transform: translateY(2px);
  }
`;

export const ForgotPassword = styled.div`
  margin-top: 20px;

  p {
    span {
      a {
        color: #5503a5;
        cursor: pointer;
        text-decoration: none;
        font-weight: 500;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

export const FooterContainer = styled.footer`
  width: 100%;
  text-align: center;
  padding: 10px 0;
  justify-content: center;
  align-items: center;
`;
