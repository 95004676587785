import React from 'react';
import { useNavigate } from 'react-router-dom';

const Sair: React.FC = () => {
  localStorage.removeItem('token');

  window.location.href = '/login';

  return <></>;
};

export default Sair;
