import styled from 'styled-components';

export const Container = styled.div`
  width: 375px;
  margin: 0 auto;
  padding: 20px 20px 80px;
  text-align: center;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  font-family: Arial, sans-serif;

  img {
    width: 100%;
    margin-top: -80px;
  }
`;

export const Title = styled.h1`
  font-size: 1.5rem;
`;

export const Text = styled.p`
  font-size: 1rem;
  color: #666;
`;

export const FormContainer = styled.div`
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
  border-radius: 10px;
`;

export const Input = styled.input`
  width: 100%; /* Ajuste para ocupar toda a largura */
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box; /* Garante que o padding não aumente a largura total */
`;

export const Select = styled.select`
  width: 95%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
`;

export const Button = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  &:hover {
    background-color: #0056b3;
  }
`;

export const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 20px;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);

  p {
    font-size: 0.9rem;
    color: #666;
  }

  img {
    height: 40px;
    width: auto;
  }
`;

export const TimeSlot = styled.div<{ selected: boolean }>`
  display: flex; 
  justify-content: center;
  align-items: center;
  width: 100%; 
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  background-color: ${(props) => (props.selected ? '#007bff' : '#e0e0e0')};
  color: ${(props) => (props.selected ? '#fff' : '#000')};
  cursor: pointer;
  font-size: 1rem;
  &:hover {
    background-color: #007bff;
    color: #fff;
  }
`;


export const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 93%;
  height: 300px;
  overflow-y: scroll;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 10px; 
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0;
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 5px;

  input[type='checkbox'] {
    margin-right: 8px;
  }
`;
