import {
  ContainerSectionCelphone,
  ContainerSocialMedia,
  ContainerSocialMediasIcons,
  ImageSectionCelphone,
  TextSectionCelphone,
} from './style';
import Intersect from '../assets/ico/Intersect.svg';
import ImagePhone from '../assets/ImagePhone.svg';
import Facebook from '../assets/ico/Facebook.svg';
import Instagram from '../assets/ico/Instagram.svg';
import Messenger from '../assets/ico/Messenger.svg';
import Telegram from '../assets/ico/Telegram.svg';
import Whatsapp from '../assets/ico/Whatsapp.svg';

const SectionInicial: React.FC = () => {
  return (
    <ContainerSectionCelphone>
      <TextSectionCelphone>
        <h1>
          Sua <span>maior parceira</span>
          <br /> em comunicação
        </h1>
        <p>
          Está pronto para promover uma <span>transformação digital</span> na
          sua empresa? <span>Conte com a Haaify</span> e otimize sua presença
          online com um atendimento impecável: atendimento automático 24 horas
          por dia com chatbots em presença multicanal: WhatsApp, Instagram,
          Facebook e mais!
        </p>
        <a
          href="https://wa.link/h5d1k4"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button>
            Converse com a gente{' '}
            <img title="Intersect" src={Intersect} alt="Icone" />
          </button>
        </a>

        <ContainerSocialMedia>
          <p>Atendimento multicanal:</p>
          <ContainerSocialMediasIcons>
            <img title="Facebook" src={Facebook} />
            <img title="Instagram" src={Instagram} />
            <img title="Whatsapp" src={Whatsapp} />
            <img title="Messenger" src={Messenger} />
            <img title="Telegram" src={Telegram} />
          </ContainerSocialMediasIcons>
        </ContainerSocialMedia>
      </TextSectionCelphone>
      <ImageSectionCelphone>
        <img title="ImagePhone" src={ImagePhone} />
      </ImageSectionCelphone>
    </ContainerSectionCelphone>
  );
};

export default SectionInicial;
