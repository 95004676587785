import React, { useEffect, useState } from 'react';
import {
  Container,
  Header,
  FilterBar,
  SearchInput,
  AddButton,
  Table,
  StatusBadge,
  Pagination,
  ModalOverlay,
  ModalContent,
  CloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContainer,
  Tab,
  Form,
  TextField,
  TextArea,
  UploadArea,
  WarningMessage,
  ProgressBarContainer,
  ProgressBar,
  HeaderImport,
} from './style';

import lapis from './assets/lapis.png';
import profilePlaceholder from './assets/upload.png';
import axiosInstance from '../../../utils/axiosInstance';

const TabelaProfissionalGestaoEquipe = () => {
  const API_URL = '/profissional/';

  const [profissionais, setProfissionais] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);
  const [unidades, setUnidades] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  /* const renderStatusBadge = (status: any) => {
    switch (status) {
      case 'Em dia':
        return <StatusBadge color="green">● Em dia</StatusBadge>;
      case 'A fazer':
        return <StatusBadge color="orange">● A fazer</StatusBadge>;
      case 'Risco':
        return <StatusBadge color="red">● Risco</StatusBadge>;
      default:
        return null;
    }
  }; */

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [selectedEspecialidade, setSelectedEspecialidade] = useState('');
  const [selectedUnidade, setSelectedUnidade] = useState('');

  const [senha, setSenha] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'nome':
        setName(value);
        break;
      case 'telefone':
        setTelefone(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'senha':
        setSenha(value);
        break;
      default:
        break;
    }
  };

  const handleChangeOptionEspecialidade = (event) => {
    setSelectedEspecialidade(event.target.value);
    console.log('especialidade selecionada:', event.target.value);
  };

  const handleChangeOptionUnidade = (event) => {
    setSelectedUnidade(event.target.value);
    console.log('unidade selecionada:', event.target.value);
  };

  const [activeTab, setActiveTab] = useState('addClient');

  useEffect(() => {
    getProfissional();
    getEspecialidades();
    getUnidades();
  }, []);

  const getProfissional = async () => {
    try {
      const response = await axiosInstance.get(API_URL);
      setProfissionais(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getEspecialidades = async () => {
    try {
      const response = await axiosInstance.get('/especialidade/');
      setEspecialidades(response.data);
    } catch (error) {
      console.error('Erro ao buscar especialidades:', error);
    }
  };

  const getUnidades = async () => {
    try {
      const response = await axiosInstance.get('/unidade/');
      setUnidades(response.data);
    } catch (error) {
      console.error('Erro ao buscar unidades:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const clienteData = {
      nome: name,
      email,
      telefone,
      senha,
      unidade_id: selectedUnidade,
      especialidade_id: selectedEspecialidade,
    };

    try {
      const response = await axiosInstance.post(API_URL, clienteData);
      console.log('Profissional adicionado com sucesso:', response);
      closeModal();
      getProfissional();
    } catch (error) {
      console.log(error);

      console.error('Erro ao adicionar Profissional:', error);
    }
  };

  const openModalEdit = async (id) => {
    console.log(id);
    const profissionalEdit = profissionais.filter((e) => e.id === id);

    setId(id);
    setName(profissionalEdit[0].nome);
    setEmail(profissionalEdit[0].email);
    setTelefone(profissionalEdit[0].telefone);
    setSelectedEspecialidade(profissionalEdit[0].especialidade_id);
    setSelectedUnidade(profissionalEdit[0].unidade_id);

    openModal();
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    const clienteData = {
      nome: name,
      email,
      telefone,
      unidade_id: selectedUnidade,
      especialidade_id: selectedEspecialidade,
    };

    try {
      const response = await axiosInstance.put(`${API_URL}${id}`, clienteData);
      console.log('Profissional editado com sucesso:', response);
      closeModal();
      getProfissional();
    } catch (error) {
      console.log(error);

      console.error('Erro ao adicionar Profissional:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`${API_URL}${id}`);
      console.log('Profissional deletado com sucesso:', id);
      closeModal();
      getProfissional();
    } catch (error) {
      console.error('Erro ao deletar Profissional:', error);
    }
  };

  return (
    <Container>
      <Header>
        <div>
          <h3>Membros da sua equipe</h3>
          <br />
          <p>
            Adicione membros, altere suas informações, defina o modelo de acesso
            para cada profissional e configure os serviços oferecidos.
          </p>
        </div>
      </Header>

      <Header>
        <FilterBar>
          {/*   <select>
            <option value="">Tudo</option> */}
          {/* <option value="Em dia">Em dia</option>
            <option value="A fazer">A fazer</option>
            <option value="Risco">Risco</option> */}
          {/*  </select>
          <SearchInput placeholder="Pesquisar" />*/}
        </FilterBar>

        <AddButton
          onClick={() => {
            setName('');
            setEmail('');
            setSenha('');
            setTelefone('');
            setSelectedEspecialidade('');
            setSelectedUnidade('');
            openModal();
          }}
        >
          Adicionar profissional +
        </AddButton>
      </Header>

      {/* <FilterBar>
        <nav>
          <a href="#">
            Tudo <span>27</span>
          </a>
          <a href="#">
            Tarefa <span>4</span>
          </a>
          <a href="#">
            Aguardando <span>4</span>
          </a>
          <a href="#">
            Em dia <span>12</span>
          </a>
          <a href="#">
            Arquivado <span>9</span>
          </a>
        </nav>
      </FilterBar> */}

      <Table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Telefone</th>
            <th>Editar</th>
          </tr>
        </thead>
        <tbody>
          {profissionais.length === 0 ? (
            <>
              <br />
              <h2>Nenhum profissional encontrado.</h2>
            </>
          ) : (
            profissionais.map((profissional, index) => (
              <tr key={index}>
                <td>{profissional.name}</td>
                <td>{profissional.email}</td>
                <td>{profissional.telefone}</td>
                <td>
                  <button onClick={() => openModalEdit(profissional.id)}>
                    <img src={lapis} alt="Editar" />
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <Pagination>
        <span>1-2 de 2</span>
        <div>
          <span>Itens por página: 15</span>
          <button>{'<'}</button>
          <span>1 / 2</span>
          <button>{'>'}</button>
        </div>
      </Pagination>

      {isModalOpen && (
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>
              <TabContainer>
                <Tab
                  active={activeTab === 'addClient'}
                  onClick={() => setActiveTab('addClient')}
                >
                  Adicionar Profissional
                </Tab>
                {/* <Tab
                  active={activeTab === 'importClients'}
                  onClick={() => setActiveTab('importClients')}
                >
                  Importar clientes
                </Tab> */}
              </TabContainer>
              <CloseButton onClick={closeModal}>×</CloseButton>
            </ModalHeader>
            <ModalBody>
              {activeTab === 'addClient' && (
                <Form>
                  <div>
                    <label>Nome completo</label>
                    <TextField
                      name="nome"
                      value={name}
                      onChange={handleChange}
                      type="text"
                      placeholder="Nome do cliente"
                    />
                    <label>Email</label>
                    <TextField
                      name="email"
                      value={email}
                      onChange={handleChange}
                      type="email"
                      placeholder="Digite o Email do cliente"
                    />
                    <label>Telefone</label>
                    <TextField
                      name="telefone"
                      value={telefone}
                      onChange={handleChange}
                      type="tel"
                      placeholder="Digite o Telefone"
                    />
                    <label>Senha</label>
                    <TextField
                      name="senha"
                      value={senha}
                      onChange={handleChange}
                      type="password"
                      placeholder="Digite a senha"
                    />
                  </div>
                  <div>
                    <label>Especialidade</label>
                    <select
                      value={selectedEspecialidade}
                      onChange={handleChangeOptionEspecialidade}
                    >
                      <option value="">Selecione a especialidade</option>
                      {especialidades.map((especialidade) => (
                        <option key={especialidade.id} value={especialidade.id}>
                          {especialidade.nome}
                        </option>
                      ))}
                    </select>

                    <label>Unidade</label>
                    <select
                      value={selectedUnidade}
                      onChange={handleChangeOptionUnidade}
                    >
                      <option value="">Selecione a unidade</option>
                      {unidades.map((unidade) => (
                        <option key={unidade.id} value={unidade.id}>
                          {unidade.nome}
                        </option>
                      ))}
                    </select>
                  </div>
                </Form>
              )}
              {/* {activeTab === 'importClients' && (
                <>
                  <HeaderImport>
                    <UploadArea>
                      <img src={profilePlaceholder} alt="Profile" />
                      <p>
                        Arraste ou <span>Clique para upload</span>
                      </p>
                      <small>Max. 5MB | CSV , Excel</small>
                    </UploadArea>
                    <div>
                      <WarningMessage>
                        <span>Atenção</span> O arquivo precisa estar nos moldes
                        para realizar o upload.
                        <button onClick={closeModal}>×</button>
                      </WarningMessage>
                      <ProgressBarContainer>
                        <p>Nome do documento.xpto</p>
                        <ProgressBar progress={30} />
                        <small>xMB</small>
                      </ProgressBarContainer>
                    </div>
                  </HeaderImport>

                  <Table>
                    <thead>
                      <tr>
                        <th>Cliente</th>
                        <th>Telefone</th>
                        <th>E-mail</th>
                        <th>Editar</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Gustavo</td>
                        <td>31999999999</td>
                        <td>email@email.com.br</td>
                        <td>
                          <button>
                            <img src={lapis} alt="Editar" />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              )} */}
            </ModalBody>
            <ModalFooter>
              <button onClick={closeModal}>Cancelar</button>
              <button type="submit">Adicionar Profissional</button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
  );
};

export default TabelaProfissionalGestaoEquipe;
