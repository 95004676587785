import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';

const CalendarioCompleto: React.FC = () => {
  const [tarefas, setTarefas] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);

  const events = [
    { title: 'Evento 1', date: '2024-11-19T10:00:00' },
    { title: 'Evento 2', date: '2024-11-19T14:00:00' },
    { title: 'Evento 3', date: '2024-11-20T09:00:00' },
    { title: 'Evento 4', date: '2024-11-21T13:30:00' },
  ];

  const handleDateClick = (arg: any) => {
    const clickedDate = arg.dateStr;
    setSelectedDate(clickedDate);

    const eventosDoDia = events
      .filter((event) => event.date.startsWith(clickedDate))
      .map((event) => event.title);

    setTarefas(eventosDoDia.length > 0 ? eventosDoDia : ['Sem eventos no dia']);
  };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 3fr',
        gap: '1rem',
        padding: '1rem',
      }}
    >
      <div style={{ borderRight: '1px solid #ccc', padding: '1rem' }}>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          locale={ptBrLocale}
          dateClick={handleDateClick}
          height="auto"
          headerToolbar={{
            left: '',
            center: 'title',
            right: '',
          }}
        />
        <h2 style={{ marginTop: '2rem' }}>
          Eventos no dia {selectedDate || ''}
        </h2>
        <ul>
          {tarefas.map((tarefa, index) => (
            <li key={index}>{tarefa}</li>
          ))}
        </ul>
      </div>

      <div style={{ padding: '1rem' }}>
        <FullCalendar
          plugins={[timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          locale={ptBrLocale}
          weekends={true}
          initialDate={selectedDate || undefined}
          events={events}
          height="90vh"
        />
      </div>
    </div>
  );
};

export default CalendarioCompleto;
