import {
  CardDescriptionPontuation,
  CardPontuation,
  CardValuePontuation,
  ContainerPontuation,
} from './style';

const Pontuation: React.FC = () => {
  return (
    <ContainerPontuation>
      <CardPontuation>
        <CardValuePontuation>
          <h1>62%</h1>
        </CardValuePontuation>
        <CardDescriptionPontuation>
          <p>
            da população mundial está
            <br />
            conectada à internet
          </p>
        </CardDescriptionPontuation>
      </CardPontuation>
      <CardPontuation>
        <CardValuePontuation>
          <h1>85%</h1>
        </CardValuePontuation>
        <CardDescriptionPontuation>
          <p>
            das empresas acreditam que <br />
            inovar é uma prioridade.{' '}
          </p>
        </CardDescriptionPontuation>
      </CardPontuation>
      <CardPontuation>
        <CardValuePontuation>
          <h1>100%</h1>
        </CardValuePontuation>
        <CardDescriptionPontuation>
          <p>
            dos seus desafios de
            <br />
            comunicação digital podem <br />
            ser superados{' '}
          </p>
        </CardDescriptionPontuation>
      </CardPontuation>
    </ContainerPontuation>
  );
};

export default Pontuation;
