import React from "react";
import TabelaProfissionalGestaoEquipe from "./Profissional/TabelaProfissionalGestaoEquipe";
import TabelaUnidadeGestaoEquipe from "./Unidade/TabelaUnidadeGestaoEquipe";
import TabelaEspecialidadeGestaoEquipe from "./Especialidade/TabelaEspecialidadeGestaoEquipe";
import TabelaServicoGestaoEquipe from "./Servico/TabelaServicoGestaoEquipe";

interface ContentProps {
  selectedSection: string;
}

const ContentGestaoEquipe: React.FC<ContentProps> = ({ selectedSection }) => {
  const renderContent = () => {
    switch (selectedSection) {
      case "Profissionais":
        return <TabelaProfissionalGestaoEquipe />;
      case "Unidades":
        return <TabelaUnidadeGestaoEquipe />;
      case "Especialidade":
        return <TabelaEspecialidadeGestaoEquipe />;
      case "Serviços":
        return <TabelaServicoGestaoEquipe />;
      default:
        return <p>Selecione uma seção</p>;
    }
  };

  return (
    <div style={{ flex: 1, padding: "20px" }}>
      {/* <h2>{selectedSection}</h2> */}
      {renderContent()}
    </div>
  );
};

export default ContentGestaoEquipe;
