import React, { useEffect, useState } from 'react';
import {
  Container,
  Header,
  FilterBar,
  SearchInput,
  AddButton,
  Table,
  StatusBadge,
  Pagination,
  ModalOverlay,
  ModalContent,
  CloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContainer,
  Tab,
  Form,
  TextField,
  TextArea,
  UploadArea,
  WarningMessage,
  ProgressBarContainer,
  ProgressBar,
  HeaderImport,
} from './style';

import lapis from './assets/lapis.png';
import profilePlaceholder from './assets/upload.png';
import axiosInstance from '../../utils/axiosInstance';

const Clientes = () => {
  const API_URL = '/cliente/';

  const [clients, setClients] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('addClient');

  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [endereco, setEndereco] = useState('');
  const [informacoesAdicionais, setInformacoesAdicionais] = useState('');
  const [senha, setSenha] = useState('');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'nome':
        setNome(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'telefone':
        setTelefone(value);
        break;
      case 'endereco':
        setEndereco(value);
        break;
      case 'informacoesAdicionais':
        setInformacoesAdicionais(value);
        break;
      case 'senha':
        setSenha(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const clienteData = {
      nome,
      email,
      telefone,
      endereco,
      informacoesAdicionais,
      senha,
    };

    console.log('passou aqui');

    console.log({
      nome,
      email,
      telefone,
      endereco,
      informacoesAdicionais,
    });

    try {
      const response = await axiosInstance.post(API_URL, clienteData);
      console.log('Cliente adicionado com sucesso:', response.data);
      closeModal();
      getClients();
    } catch (error) {
      console.error('Erro ao adicionar cliente:', error);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  const getClients = async () => {
    try {
      const response = await axiosInstance.get(API_URL);
      setClients(response.data);
    } catch (error) {
      console.error('Erro ao buscar clientes:', error);
    }
  };

  const renderStatusBadge = (status) => {
    switch (status) {
      case 'Em dia':
        return <StatusBadge color="green">● Em dia</StatusBadge>;
      case 'A fazer':
        return <StatusBadge color="orange">● A fazer</StatusBadge>;
      case 'Risco':
        return <StatusBadge color="red">● Risco</StatusBadge>;
      default:
        return null;
    }
  };

  return (
    <Container>
      <Header>
        <h1>Clientes</h1>
        <AddButton onClick={openModal}>Adicionar cliente +</AddButton>
      </Header>

      <FilterBar>
        <select>
          <option value="">Tudo</option>
          <option value="Em dia">Em dia</option>
          <option value="A fazer">A fazer</option>
          <option value="Risco">Risco</option>
        </select>
        <SearchInput placeholder="Pesquisar" />
      </FilterBar>

      <Table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Telefone</th>
            <th>Status</th>
            <th>Editar</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client, index) => (
            <tr key={index}>
              <td>{client.nome}</td>
              <td>{client.email}</td>
              <td>{client.telefone}</td>
              <td>{renderStatusBadge(client.status)}</td>
              <td>
                <button>
                  <img src={lapis} alt="Editar" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination>
        <span>1-2 de 2</span>
        <div>
          <span>Itens por página: 15</span>
          <button>{'<'}</button>
          <span>1 / 2</span>
          <button>{'>'}</button>
        </div>
      </Pagination>

      {isModalOpen && (
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>
              <TabContainer>
                <Tab
                  active={activeTab === 'addClient'}
                  onClick={() => setActiveTab('addClient')}
                >
                  Adicionar cliente
                </Tab>
                <Tab
                  active={activeTab === 'importClients'}
                  onClick={() => setActiveTab('importClients')}
                >
                  Importar clientes
                </Tab>
              </TabContainer>
              <CloseButton onClick={closeModal}>×</CloseButton>
            </ModalHeader>
            <ModalBody>
              {activeTab === 'addClient' && (
                <Form>
                  <div>
                    <label>Nome completo</label>
                    <TextField
                      type="text"
                      placeholder="Nome do cliente"
                      name="nome"
                      value={nome}
                      onChange={handleChange}
                    />
                    <label>Endereço</label>
                    <TextField
                      type="text"
                      placeholder="Endereço"
                      name="endereco"
                      value={endereco}
                      onChange={handleChange}
                    />
                    <label>Email</label>
                    <TextField
                      type="email"
                      placeholder="Email do cliente"
                      name="email"
                      value={email}
                      onChange={handleChange}
                    />
                    <label>Telefone</label>
                    <TextField
                      type="text"
                      placeholder="Telefone"
                      name="telefone"
                      value={telefone}
                      onChange={handleChange}
                    />
                    <label>Senha</label>
                    <TextField
                      type="password"
                      placeholder="Digite sua senha"
                      name="senha"
                      value={senha}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <label>Informações adicionais</label>
                    <TextArea
                      placeholder="Adicione informações extras..."
                      name="informacoesAdicionais"
                      value={informacoesAdicionais}
                      onChange={handleChange}
                    />
                  </div>
                  <ModalFooter>
                    <button type="button" onClick={closeModal}>
                      Cancelar
                    </button>
                    <button type="submit" onClick={handleSubmit}>
                      Adicionar cliente
                    </button>
                  </ModalFooter>
                </Form>
              )}
              {activeTab === 'importClients' && (
                <HeaderImport>
                  <UploadArea>
                    <img src={profilePlaceholder} alt="Profile" />
                    <p>
                      Arraste ou <span>Clique para upload</span>
                    </p>
                    <small>Max. 5MB | CSV , Excel</small>
                  </UploadArea>
                </HeaderImport>
              )}
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
  );
};

export default Clientes;
