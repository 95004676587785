import React from 'react';
import { Container, Logo, Title, Message, HomeLink } from './style';
import logo from '../../assets/logo.svg'; // Substitua pelo caminho correto da sua logo
import Footer from '../Home/components/Footer/Footer';

const NotFound: React.FC = () => {
  return (
    <>
      <Container>
        <Logo src={logo} alt="Logo" />
        <Title>404 - Não Encontrado</Title>
        <Message>Oops, acho que você se perdeu...</Message>
        <HomeLink href="/">Voltar ao inicio</HomeLink>
      </Container>
      <Footer />
    </>
  );
};

export default NotFound;
