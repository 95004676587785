import styled from 'styled-components';

export const ContainerRobot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerRobotText = styled.div`
  /* margin-top: 300px; */
  width: 600px;
  margin-left: auto;
  padding: 20px;

  input {
    padding: 10px;
    width: 80%;
    border: 1px solid #86869e;
    border-radius: 4px;
    font-size: 15px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  h1 {
    font-size: 52px;
    font-weight: 500;

    @media (max-width: 768px) {
      font-size: 32px; // Tamanho menor para telas pequenas
    }
  }

  h3 {
    font-size: 22px;
    font-weight: 300;

    @media (max-width: 768px) {
      font-size: 18px; // Tamanho menor para telas pequenas
    }
  }

  p {
    padding: 20px;
    background-color: white;
    color: #86869e;
    font-size: 16px;

    @media (max-width: 768px) {
      font-size: 16px; // Ajuste do tamanho da fonte para responsividade
    }
  }

  @media (max-width: 768px) {
    margin-top: 20px;
    width: 90%;
    text-align: center;
  }
`;

export const ContainerRobotImage = styled.div`
  margin-top: 100px;
  margin-right: auto;

  img {
    margin-right: auto;
    text-align: center;
    max-width: 100%;
    height: auto;

    @media (max-width: 768px) {
      margin-top: 20px; // Espaço entre o texto e a imagem na versão responsiva
    }
  }

  @media (max-width: 768px) {
    margin-top: 20px;
    margin-right: 0;
  }
`;
