import React from 'react';
import {
  ContainerFooterHome,
  FooterLink,
  FooterInfo,
  FooterSocial,
  FooterSectionInfo,
  FooterSectionLinks,
  FooterSectionImage,
} from './style';

import Logo from '../../components/assets/logo_white.svg';
import FacebookLogo from '../../components/assets/footer/Facebook.svg';
import InstagramLogo from '../../components/assets/footer/Instagram.svg';
import WhatsappLogo from '../../components/assets/footer/Whatsapp.svg';
import LinkedinLogo from '../../components/assets/footer/Linkedin.svg';

const Footer: React.FC = () => {
  return (
    <ContainerFooterHome>
      <FooterSectionImage>
        <img src={Logo} alt="Haaify" />
      </FooterSectionImage>

      <FooterSectionLinks>
        <FooterLink href="#">FAQ</FooterLink>
        <FooterLink href="#">Privacy policy</FooterLink>
        <FooterLink href="#">Terms and conditions</FooterLink>
      </FooterSectionLinks>

      <FooterSectionInfo>
        <FooterInfo>
          A Haaify vai te ajudar a promover uma transformação digital na
          comunicação com seus clientes. Vamos conversar!
        </FooterInfo>
        <FooterInfo>
          <a href="mailto:info@haaify.com">info@haaify.com</a>
        </FooterInfo>
      </FooterSectionInfo>

      <FooterSocial>
        <a href="https://www.facebook.com/?locale=pt_BR">
          <img src={FacebookLogo} alt="Facebook" />
        </a>
        <a href="https://www.instagram.com">
          <img src={InstagramLogo} alt="Instagram" />
        </a>
        <a href="#">
          <img src={WhatsappLogo} alt="WhatsApp" />
        </a>
        <a href="https://www.linkedin.com/company/haaify/">
          <img src={LinkedinLogo} alt="LinkedIn" />
        </a>
      </FooterSocial>
    </ContainerFooterHome>
  );
};

export default Footer;
