import React, { useState } from 'react';
import {
  Sidebar,
  Logo,
  Menu,
  MenuItem,
  StyledNavLink,
  SubMenu,
  SubMenuItem,
  DropdownToggle,
  DropdownIcon,
  BottomMenu,
  ToggleButton,
  FloatingChatInput,
  FloatingChatContainer,
  FloatingResponse,
  LoadingMessage,
  RobocopDiv,
} from './style';
import logoImage from '../../assets/logo.svg';
import contact from './assets/Contacts.svg';
import calendar from './assets/Calendar.svg';
import user from './assets/Users.svg';
import trending from './assets/Trending_up.svg';
import setinha from './assets/Seta.svg';
import settings from './assets/Settings.svg';
import headphones from './assets/Headphones.svg';
import robocop from './assets/TED-removebg-preview.png';

import miniLogo from '../../../../assets/logo_fadein.png';
import axios from 'axios';

const Navbar: React.FC = () => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const toggleSidebar = () => {
    setIsMinimized(!isMinimized);
  };

  /* Haaify AI */

  const [isChatVisible, setIsChatVisible] = useState(false);
  const [userMessage, setUserMessage] = useState('');
  const [aiResponse, setAiResponse] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSendMessage = async () => {
    if (userMessage.trim()) {
      setAiResponse('');
      setLoading(true);

      try {
        const response = await axios.get(
          `https://langchainhaaify-app-4c2dj.ondigitalocean.app/api/generate-answer?question=${userMessage}`
        );
        setAiResponse(response.data.response);
      } catch (error) {
        setAiResponse('Desculpe, TED está descansando.');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Sidebar isMinimized={isMinimized}>
      <Logo>
        {!isMinimized ? (
          <img src={logoImage} alt="Haaify Logo" />
        ) : (
          <img src={miniLogo} alt="Haaify Logo" />
        )}
      </Logo>
      <ToggleButton onClick={toggleSidebar}>
        {isMinimized ? '>' : '<'}
      </ToggleButton>
      <Menu>
        {/* <MenuItem>
          <StyledNavLink to="/d/agenda">
            <img src={contact} alt="" />
            {!isMinimized && 'Minha agenda'}
          </StyledNavLink>
        </MenuItem>
        <MenuItem>
          <StyledNavLink to="/d/calendario">
            <img src={calendar} alt="" />
            {!isMinimized && 'Calendário'}
          </StyledNavLink>
        </MenuItem> */}
        {/* <MenuItem>
          <DropdownToggle onClick={toggleSubMenu}>
            <img src={user} alt="" />
            {!isMinimized && 'Clientes'}
            {!isMinimized && (
              <DropdownIcon src={setinha} alt="" isOpen={isSubMenuOpen} />
            )}
          </DropdownToggle>
          {isSubMenuOpen && !isMinimized && (
            <SubMenu>
              <SubMenuItem>
                <StyledNavLink to="/d/clientes/novo">
                  Novo cliente
                </StyledNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <StyledNavLink to="/d/clientes/ver">Ver clientes</StyledNavLink>
              </SubMenuItem>
            </SubMenu>
          )}
        </MenuItem> */}
        <MenuItem>
          <StyledNavLink to="/d/home">
            <img src={headphones} alt="" />
            {!isMinimized && 'Ted'}
          </StyledNavLink>
        </MenuItem>

        <MenuItem>
          <StyledNavLink to="/d/dash">
            <img src={trending} alt="" />
            {!isMinimized && 'Dashboard'}
          </StyledNavLink>
        </MenuItem>

        <MenuItem>
          <StyledNavLink to="/d/enviomsgs">
            <img src={calendar} alt="" />
            {!isMinimized && 'Disparo'}
          </StyledNavLink>
        </MenuItem>

        <MenuItem>
          <StyledNavLink to="/d/clientes">
            <img src={user} alt="" />
            {!isMinimized && 'Clientes'}
          </StyledNavLink>
        </MenuItem>

        <MenuItem>
          <StyledNavLink to="/d/gestao">
            <img src={user} alt="" />
            {!isMinimized && 'Gestão'}
          </StyledNavLink>
        </MenuItem>

        <MenuItem>
          <StyledNavLink to="/d/calendario">
            <img src={calendar} alt="" />
            {!isMinimized && 'Calendario'}
          </StyledNavLink>
        </MenuItem>
      </Menu>
      <BottomMenu>
        <MenuItem>
          <StyledNavLink to="/d/config">
            <img src={settings} alt="" />
            {!isMinimized && 'Configurações'}
          </StyledNavLink>
        </MenuItem>
        {/*
        <MenuItem>
          <StyledNavLink to="/d/ajuda">
            <img src={headphones} alt="" />
            {!isMinimized && 'Ajuda e Suporte'}
          </StyledNavLink>
        </MenuItem> */}
        <MenuItem>
          <StyledNavLink to="/d/sair">
            <img src={headphones} alt="" />
            {!isMinimized && 'Sair'}
          </StyledNavLink>
        </MenuItem>
      </BottomMenu>
      {/*       {isChatVisible && <></>}
      <FloatingChatContainer>
        <RobocopDiv>
          <img src={robocop} alt="robocop" />
        </RobocopDiv>
        <FloatingChatInput
          type="text"
          placeholder="Pergunte ao TED!"
          value={userMessage}
          onChange={(e) => setUserMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleSendMessage();
          }}
        />
        {loading && <LoadingMessage />}{' '}
        {!loading && aiResponse && (
          <FloatingResponse>{aiResponse}</FloatingResponse>
        )}
      </FloatingChatContainer> */}
    </Sidebar>
  );
};

export default Navbar;
