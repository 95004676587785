import styled from 'styled-components';

export const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  margin-top: 50px;
`;

export const Section = styled.div`
  margin-top: 20px;

  h2 {
    color: #5a5a5a;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
  }
`;

export const TabMenu = styled.div`
  display: flex;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;

  span.active {
    color: #6a1b9a;
    border-bottom: 2px solid #6a1b9a;
  }

  span {
    margin-right: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: 500;
    color: #5503a5;
  }
`;

export const ProfilePhotoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 20px;

  label {
    font-size: 16px;
    margin-bottom: 10px;
    color: #5a5a5a;
  }

  .photo-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px dashed #ccc;
    padding: 20px;
    width: 150px;
    text-align: center;
    border-radius: 8px;

    img {
      width: 50px;
      height: 50px;
      margin-bottom: 10px;
    }

    p {
      font-size: 12px;
      color: #7a7a7a;
      margin: 5px 0;
    }

    a {
      color: #6a1b9a;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const FormSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-right: 20px;
  margin-left: 20px;

  label {
    margin-bottom: 5px;
    color: #5a5a5a;
    font-size: 14px;
  }
`;

export const NotificationOption = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  input[type='checkbox'] {
    width: 18px;
    height: 18px;
    accent-color: #6a1b9a;
  }

  div {
    display: flex;
    flex-direction: column;

    label {
      font-weight: 500;
      color: #5a5a5a;
    }

    p {
      margin: 0;
      font-size: 14px;
      color: #7a7a7a;
    }
  }
`;

export const StyledInput = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
`;

export const StyledTextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  resize: none;
`;

export const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
`;

export const Button = styled.button<{ primary?: boolean }>`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  color: #fff;

  background-color: ${(props) => (props.primary ? '#5503a5' : '#ccc')};

  &:hover {
    opacity: 0.9;
  }
`;
