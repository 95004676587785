import React from 'react';
import styled from 'styled-components';

const WhatsAppPreviewContainer = styled.div`
  background-color: #5503a5;
  border-radius: 10px;
  padding: 15px;
  width: 360px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-left: 20px;
  color: white;
  position: absolute;
  right: 8%;
  top: 25%;
`;

const MessageBubble = styled.div`
  background-color: #128c7e;
  border-radius: 7.5px 0px 7.5px 7.5px;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;
  font-size: 14px;
  color: white;
  max-width: 100%;
  line-height: 1.5;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid #128c7e;
    border-right: 10px solid transparent;
  }
`;

const MessageImage = styled.img`
  max-width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const WhatsAppFooter = styled.div`
  font-size: 12px;
  color: #c7c7c7;
  text-align: right;
`;

const MessageButton = styled.div``;

const formatMessageWithLineBreaks = (messageContent) => {
  // Verifica se o messageContent é uma string
  if (typeof messageContent === 'string') {
    // Trata strings e quebra por '\n'
    return messageContent.split('\\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  } else if (React.isValidElement(messageContent)) {
    // Se for um React element, extraímos o texto de suas props
    return React.Children.map(messageContent, (child) => {
      if (typeof child === 'string') {
        // Se o filho for uma string, aplicamos a quebra de linha
        return child.split('\\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ));
      } else if (React.isValidElement(child)) {
        // Caso o filho seja outro React element, renderizamos o próprio componente
        return React.cloneElement(child, {
          children: formatMessageWithLineBreaks(child.props.children),
        });
      }
      return child;
    });
  } else {
    // Caso não seja string nem React element, retorna o conteúdo como está
    return messageContent;
  }
};

const WhatsAppPreview = ({ template, imageUploadLink, previewMessage }) => {
  if (!template) {
    return null;
  }

  console.log(previewMessage);

  let imageLink = imageUploadLink || null;

  if (!imageLink && template?.components?.find((c) => c.type === 'HEADER')) {
    const headerComponent = template?.components.find(
      (c) => c.type === 'HEADER'
    );
    imageLink = headerComponent?.example?.header_handle?.[0] || null;
  }

  let messageContent = 'Mensagem de exemplo';
  let buttons = null;

  console.log(template);

  if (template?.components && template?.components.length > 0) {
    const imageComponent = template.components.find((c) => c.type === 'HEADER');
    const bodyComponent = template.components.find((c) => c.type === 'BODY');
    const buttonsComponent = template.components.find(
      (c) => c.type === 'BUTTONS'
    );

    console.log('Image Component:', imageComponent);

    if (bodyComponent) {
      messageContent = bodyComponent?.text || 'Mensagem de exemplo';
    }

    if (imageComponent) {
      console.log('Image Component:', imageComponent);

      if (imageUploadLink !== null) {
        console.log(imageUploadLink);
        imageLink = imageUploadLink;
      } else {
        imageLink = imageComponent?.example?.header_handle?.[0];
      }
      console.log('Passou na verificação de imagem');
    }

    if (buttonsComponent) {
      buttons = buttonsComponent?.buttons?.map((b) => {
        return (
          <button
            style={{
              padding: '5px',
              backgroundColor: 'gray',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              margin: '5px',
            }}
            key={b.text}
          >
            {b.text}
          </button>
        );
      });
      /* console.log('Passou na verificação de botões');
      console.log(buttons); */
    }

    /* console.log('Message Content:', messageContent); */
  }

  return (
    <WhatsAppPreviewContainer>
      {imageLink && <MessageImage src={imageLink} alt="Imagem da mensagem" />}
      <MessageBubble>
        {/* {()} */}
        {previewMessage}
      </MessageBubble>
      <WhatsAppFooter>
        {new Date().getHours() + ':' + new Date().getMinutes()}
      </WhatsAppFooter>
      {buttons && <MessageButton>{buttons}</MessageButton>}
    </WhatsAppPreviewContainer>
  );
};

export default WhatsAppPreview;
