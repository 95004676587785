import styled from 'styled-components';

export const ContainerPontuation = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: auto;

  margin-top: 40px;
`;

export const CardPontuation = styled.div`
  width: 30%;
  padding: 20px;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const CardValuePontuation = styled.div`
  h1 {
    font-size: 56.6px;
    background: linear-gradient(
      180deg,
      rgba(90, 7, 172, 1) 20%,
      rgba(85, 3, 165, 0.5) 100%
    );
    background-clip: text;
    color: transparent;
    line-height: 84px;
    font-weight: bolder;
    text-align: center;
  }
`;

export const CardDescriptionPontuation = styled.div`
  p {
    font-size: 1.1rem;
    font-weight: bolder;
    text-align: center;
    color: #757575;
  }
`;
