import styled from 'styled-components';

export const ContainerOportunity = styled.div`
  margin-top: 100px;
  justify-content: center;
`;

export const ContainerOportunityText = styled.div`
  text-align: center;

  h1 {
    font-size: 52px;
    font-weight: bold;
    color: #3d3d3d;
  }

  @media screen and (max-width: 425px) {
    h1 {
      font-size: 32px;
    }
  }

  p {
    font-size: 1.1rem;
    font-weight: 500;
    color: #b0b0b0;
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;

    margin-top: 30px;
  }

  @media screen and (max-width: 425px) {
    p {
      text-align: justify;
    }
  }
`;

export const ContainerOportunityCards = styled.div`
  display: flex;
  /* justify-content: space-evenly; */
  flex-wrap: wrap;

  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
`;

export const CardOportunityOne = styled.div`
  width: 50%;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 3.12px 9.37px 21.85px rgba(0, 0, 0, 0.06);

  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1100px) {
    width: 90%;
    margin-bottom: 50px;
  }
`;

export const CardOportunityTwo = styled.div`
  width: 30%;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 3.12px 9.37px 21.85px rgba(0, 0, 0, 0.06);

  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const CardIcon = styled.div`
  flex: 0 0 auto;
  margin-right: 20px;

  img {
    width: 50px;
    height: 50px;
  }

  margin-bottom: 20px;
`;

export const CardContent = styled.div`
  flex: 1;
`;

export const CardLogo = styled.img`
  float: right;
  margin-left: 20px;
  width: 50px;
  height: 20px;
`;

export const CardTitle = styled.h3`
  margin: 0;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #3d3d3d;
  font-weight: bold;
`;

export const CardDescription = styled.p`
  margin: 10px 0 0;
  font-size: 1rem;
  color: #6d6d6d;
  line-height: 2;
  font-weight: 500;
`;

export const ContainerCardIcon = styled.div`
  display: flex;
  justify-content: space-between;

  .partner-ico {
    width: 100%;
    height: max-content;
  }
`;

export const ContainerOportunityCardsSecond = styled.div`
  width: 80%;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-evenly;
  margin-top: 75px;

  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1100px) {
    width: 90%;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
  }
`;

export const CardOportunityThree = styled.div`
  width: 26%;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 3.12px 9.37px 21.85px rgba(0, 0, 0, 0.06);

  @media screen and (max-width: 1100px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;
